;(function () {
    /* start-dbtfy-addons */

    /* start-dbtfy-addtocart-animation */ function themeAddtocartAnimation() {
        function AddtocartAnimation() {
            const t = theme.settings.dbtfyATCAnimationType,
                n = 1e3 * theme.settings.dbtfyATCAnimationInterval,
                a = theme.variables.animationSlow,
                o = `slow ${t}`
            $(
                '.product-single__add-to-cart .btn--add-to-cart:not([disabled])'
            ).addClass('btn--addtocart_animation'),
                setInterval(function () {
                    const t = $('.btn--addtocart_animation')
                    t.addClass(o),
                        setTimeout(function () {
                            t.removeClass(o)
                        }, a)
                }, n)
        }
        AddtocartAnimation()
    } /* end-dbtfy-addtocart-animation */

    /* start-dbtfy-age-check */ function themeAgeCheck() {}
    function themeAgeCheckDestroy() {} /* end-dbtfy-age-check */

    /* start-dbtfy-agree-to-terms */ function themeAgreeToTerms() {}
    function themeAgreeToTermsDestroy() {} /* end-dbtfy-agree-to-terms */

    /* start-dbtfy-automatic-geolocation */ function themeAutomaticGeolocation() {} /* end-dbtfy-automatic-geolocation */

    /* start-dbtfy-back-in-stock */ function themeBackInStock() {
        function BackInStock() {
            const e = document.querySelector('.dbtfy-back_in_stock')
            if (!e) return
            const t = e.querySelector('form'),
                o = document.querySelectorAll('.product-single__variants'),
                r = []
            let n = !1
            function i(e, t, o) {
                if (n) return void (n = !1)
                const r = e.getAttribute('data-section-id'),
                    i = e.querySelector('.product-single__meta--wrapper'),
                    c = e.querySelector('.notify_form__label'),
                    s = e.querySelector('.notify_form_message'),
                    a = e.querySelector('.product-single__add-to-cart'),
                    d = e.querySelector("[id^='MainProductSelect']"),
                    u = e.querySelector('.notify_form__inputs'),
                    l = e.querySelector('.notify_form__success'),
                    p = +d.value,
                    y = t[r] ? t[r].variants : [],
                    S = !!i && i.classList.contains('variant-soldout')
                let h = null,
                    _ = !1
                if (
                    ($.each(y, function (e, t) {
                        p === this.id && ((h = this), (_ = this.available))
                    }),
                    l && o && l.setAttribute('hidden', 'hidden'),
                    !_ || S)
                ) {
                    const e = u.dataset.url,
                        t = u.dataset.bodyMessage
                    u.removeAttribute('hidden'),
                        c.removeAttribute('hidden'),
                        s.setAttribute('data-body', `${t}${e}?variant=${p}`),
                        s.setAttribute('value', `${t}${e}?variant=${p}`),
                        a && a.setAttribute('hidden', 'hidden')
                } else
                    u.setAttribute('hidden', 'hidden'),
                        c.setAttribute('hidden', 'hidden'),
                        a && a.removeAttribute('hidden')
            }
            o.length &&
                o.forEach(e => {
                    const t = e.closest(
                            "[data-section-type='product-template'], [data-section-type='quick-view-template']"
                        ),
                        o = t.getAttribute('data-section-id'),
                        c =
                            +t.querySelector('.product-single').dataset
                                .productId,
                        s = t.querySelector('.product-single__meta--wrapper')
                    if (
                        ((function (e, t) {
                            const o = sessionStorage.getItem('notify_products'),
                                r = (o ? JSON.parse(o) : []).find(
                                    e => e.id === t
                                )
                            if (!r) return
                            const i = e.dataset.productSelector,
                                c = e.querySelector(
                                    `[id^='MainProductSelect'] option[value='${r.variantId}']`
                                )
                            if (!c) return
                            const s = [
                                c.getAttribute('data-option-1'),
                                c.getAttribute('data-option-2'),
                                c.getAttribute('data-option-3'),
                            ]
                            switch (i) {
                                case 'radio':
                                    s.forEach((t, o) => {
                                        const r = e.querySelectorAll(
                                            `.single-option-selector__radio[name='option${
                                                o + 1
                                            }'][value='${CSS.escape(
                                                t
                                            )}'], .input-color_swatches[data-option-position='option${
                                                o + 1
                                            }'][value='${CSS.escape(t)}']`
                                        )
                                        r.forEach(e => {
                                            ;(e.checked = !0),
                                                e.dispatchEvent(
                                                    new Event('change')
                                                )
                                        })
                                    })
                                    break
                                case 'select':
                                    s.forEach((t, o) => {
                                        const r = e.querySelectorAll(
                                            '.single-option-selector__radio'
                                        )
                                        if (!r.length) return
                                        const n = r[o]
                                        if (!n) return
                                        const i = n.querySelector(
                                            `option[value='${CSS.escape(t)}']`
                                        )
                                        i &&
                                            ((n.value = t),
                                            n.dispatchEvent(
                                                new Event('change')
                                            ))
                                    })
                            }
                            const a = e.querySelector('.notify_form__success'),
                                d = e.querySelector('.notify_form__label'),
                                u = e.querySelector('.notify_form__inputs')
                            a.removeAttribute('hidden'),
                                d.setAttribute('hidden', 'hidden'),
                                u.setAttribute('hidden', 'hidden'),
                                (n = !0)
                        })(t, c),
                        $(`#ProductJson-${o}`).length)
                    ) {
                        if (
                            ((r[o] = JSON.parse(
                                document.getElementById(`ProductJson-${o}`)
                                    .innerHTML
                            )),
                            s)
                        ) {
                            const e = {
                                attributes: !0,
                                childList: !1,
                                subtree: !1,
                            }
                            new MutationObserver(() => {
                                i(t, r)
                            }).observe(s, e)
                        }
                        document.body.addEventListener('change', function (e) {
                            i(t, r, !0)
                        }),
                            i(t, r, !0)
                    }
                }),
                sessionStorage.removeItem('notify_products'),
                document
                    .querySelectorAll('.input-color_swatches:checked')
                    .forEach(e => {
                        const t = e.closest('.custom_swatch_elements'),
                            o = t
                                ? t.querySelector('.label-color_swatches')
                                : null,
                            r = o ? o.getAttribute('data-label') : null,
                            n = e.value
                        o &&
                            r &&
                            (o.innerHTML = `${r}: <span class="variant-label-option-value">${n}</span>`)
                    }),
                t &&
                    t.addEventListener('submit', () => {
                        const e = t.closest(
                                "div[data-section-type='product-template']"
                            ),
                            o =
                                +e.querySelector('.product-single').dataset
                                    .productId,
                            r = e
                                .querySelector("[id^='MainProductSelect']")
                                .querySelector('option:checked'),
                            n = r ? +r.value : null,
                            i = sessionStorage.getItem('notify_products')
                        let c = []
                        i && (c = JSON.parse(i)),
                            (c = c.filter(e => e.id !== o)).push({
                                id: o,
                                variantId: n,
                            }),
                            sessionStorage.setItem(
                                'notify_products',
                                JSON.stringify(c)
                            )
                    })
        }
        BackInStock()
    }
    function themeBackInStockDestroy() {
        function destroyBackInStock() {
            const c = document.querySelectorAll('.dbtfy-back_in_stock')
            c.length &&
                c.forEach(c => {
                    c.remove()
                })
        }
        destroyBackInStock()
    } /* end-dbtfy-back-in-stock */

    /* start-dbtfy-cart-countdown */ function themeCartCountdown() {
        function CartCountdown() {
            const t = this,
                e = $('body'),
                n = document.body.classList.contains('template-cart')
            let o
            function a() {
                ;(t.dbtfy = $('.dbtfy-cart_countdown')),
                    (t.$originalMsg = $('.dbtfy-cart_countdown_default_msg')),
                    (t.$msgWhenOver = $('.dbtfy-cart_countdown_msg_t_over')),
                    (t.$cartCountdown = t.dbtfy),
                    (t.addToCartForm = $("form[action*='/cart/add']")),
                    (t.cartCount = t.dbtfy.data('cart-count')),
                    (t.textMinutes = t.dbtfy.data('string-minute')),
                    (t.textSeconds = t.dbtfy.data('string-second'))
            }
            function d() {
                clearInterval(o)
            }
            function s() {
                t.$originalMsg.addClass('hide'),
                    t.$msgWhenOver.removeClass('hide')
            }
            function c(n) {
                const a = $('.minutes-cart_countdown'),
                    c = $('.seconds-cart_countdown'),
                    r = new Date(n).getTime()
                if (new Date().getTime() - r > 0)
                    return t.$cartCountdown.show(), void s()
                function i(e) {
                    const n = new Date().getTime(),
                        o = r - n,
                        i = Math.floor((o % 36e5) / 6e4),
                        u = Math.floor((o % 6e4) / 1e3)
                    u || 0 === u
                        ? (i <= 0
                              ? a.hide()
                              : (a.show(), a.text(i + t.textMinutes)),
                          c.text(u + t.textSeconds),
                          1 !== e && i <= 0 && u <= 0 && (d(), s()))
                        : d()
                }
                sessionStorage.setItem('cart_endtime', n),
                    i(1),
                    clearInterval(o),
                    (o = setInterval(i, 1e3)),
                    t.$cartCountdown.show(),
                    e.addClass('cart-countdown-active')
            }
            function r() {
                const e = t.dbtfy.data('minutes'),
                    n = Date.parse(new Date()),
                    o = new Date(n + 60 * e * 1e3),
                    a = sessionStorage.getItem('cart_endtime')
                window.wishlistAdded
                    ? (c(o), (window.wishlistAdded = !1))
                    : sessionStorage.cart_endtime
                    ? c(a)
                    : c(o)
            }
            if ((a(), sessionStorage.cart_endtime)) {
                c(sessionStorage.getItem('cart_endtime'))
            } else n && t.dbtfy.length && r()
            e.on('ajaxCart.afterCartLoad', function (n, o) {
                a(),
                    r(),
                    o.item_count ||
                        (d(),
                        t.$cartCountdown.hide(),
                        e.removeClass('cart-countdown-active'))
            }),
                0 === t.cartCount && d()
        }
        CartCountdown(),
            $('body').on('afterRecommendationLoad', function (t) {
                CartCountdown()
            })
    }
    function themeCartCountdownDestroy() {
        function destroyCartCountdown() {
            const o = document.querySelectorAll('.dbtfy-cart_countdown')
            o.length &&
                o.forEach(o => {
                    o.remove()
                })
        }
        destroyCartCountdown(),
            $('body').on('ajaxCart.afterCartLoad', function (o, t) {
                destroyCartCountdown()
            }),
            $('body').on('afterRecommendationLoad', function (o) {
                destroyCartCountdown()
            })
    } /* end-dbtfy-cart-countdown */

    /* start-dbtfy-cart-discount */ function themeCartDiscount() {
        function CartDiscount() {
            var t = $('.dbtfy-cart_discount'),
                n = $('.input-cart_discount')
            t.length &&
                (n.on('keydown', function (t) {
                    13 === t.keyCode &&
                        (t.preventDefault(),
                        '' !== $(this).val() &&
                            $(t.target)
                                .closest('form')
                                .find("button[name='checkout']")
                                .trigger('click'))
                }),
                $(document).on('click', '.cd-apply', function (t) {
                    if (
                        (t.preventDefault(),
                        '' !==
                            $(t.target)
                                .closest('.CartDiscount')
                                .find('.input-cart_discount')
                                .val())
                    ) {
                        var n = $(t.target)
                            .closest('form')
                            .find("button[name='checkout']")
                        n.length ||
                            (n = $(t.target)
                                .closest('#CartDrawer')
                                .find("button[name='checkout']")),
                            n.trigger('click')
                    }
                }))
        }
        CartDiscount(),
            $('body').on('ajaxCart.afterCartLoad', function (t, n) {
                CartDiscount()
            })
    }
    function themeCartDiscountDestroy() {
        function destroyCartDiscount() {
            const t = document.querySelectorAll('.dbtfy-cart_discount')
            t.length &&
                t.forEach(t => {
                    t.remove()
                })
        }
        destroyCartDiscount(),
            $('body').on('ajaxCart.afterCartLoad', function (t, o) {
                destroyCartDiscount()
            })
    } /* end-dbtfy-cart-discount */

    /* start-dbtfy-cart-favicon */ function themeCartFavicon() {
        function CartFavicon(t) {
            const e = t ? t.item_count : theme.variables.cartCount
            if ('' !== theme.settings.cartFaviconImage) {
                const t = document.getElementById('favicon')
                e > 0
                    ? t.setAttribute('href', theme.settings.cartFaviconImage)
                    : t.setAttribute('href', theme.settings.faviconImage)
            }
        }
        CartFavicon(),
            $('body').on('ajaxCart.afterCartLoad', function (t, e) {
                CartFavicon(e)
            })
    } /* end-dbtfy-cart-favicon */

    /* start-dbtfy-cart-goal */ function themeCartGoal() {}
    function themeCartGoalDestroy() {} /* end-dbtfy-cart-goal */

    /* start-dbtfy-cart-savings */ function themeCartSavings() {
        function CartSavings() {
            const t = {
                cartCurrentValues: {
                    price: 0,
                    compareAtPrice: 0,
                    cartSavingsValue: 0,
                    cartTotalWithDiscount: 0,
                },
                drawerCartCurrentValues: {
                    price: 0,
                    compareAtPrice: 0,
                    cartSavingsValue: 0,
                    cartTotalWithDiscount: 0,
                },
            }
            function e(e) {
                function a() {
                    let a = ''
                    a = e
                        ? 'drawer' === e
                            ? '#CartDrawer'
                            : '.dbtfy-cart-page-container'
                        : '#CartDrawer, .dbtfy-cart-page-container'
                    const r = document.querySelectorAll(a),
                        n = theme.strings.moneyFormat
                    r.forEach(e => {
                        const a = e.querySelector('.cart_savings-wrapper')
                        if (!a) return
                        const r = e.querySelector('[data-cart-total]'),
                            c = e.querySelector('[data-cart-total-value]'),
                            s = a.parentElement.querySelector('.cart-discount'),
                            o = s ? +s.dataset.discountValue : 0,
                            i = a.querySelector('.cart_savings-wrapper-text'),
                            u = +a.dataset.totalWithComparePrice,
                            l = +a.dataset.totalSavingsByComparePrice,
                            d = theme.Currency.formatMoney(u, n)
                        let p = l
                        if (p > 0 || o > 0) {
                            let e = u - p
                            const l = a.closest('.main-content')
                                    ? 'cartCurrentValues'
                                    : 'drawerCartCurrentValues',
                                y = t[l]
                            if (
                                s &&
                                ((e = u - (p += o)),
                                y.price === e && y.compareAtPrice === u)
                            )
                                return
                            if (y.price === e && y.compareAtPrice === u) return
                            ;(y.price = e), (y.compareAtPrice = u)
                            const f = theme.Currency.formatMoney(e, n)
                            ;(i.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                p,
                                n
                            )}</span>`),
                                m(r, !0, d, f),
                                (c.innerHTML = `<span class="money">${f}</span>`),
                                a.removeAttribute('hidden')
                        } else
                            m(r, !1, d),
                                (c.innerHTML = `<span class="money">${d}</span>`),
                                a.setAttribute('hidden', 'hidden')
                        function m(t, e, a, r) {
                            t.querySelector('.cart__subtotal-compare-at') &&
                                (t.innerHTML = ''),
                                (t.innerHTML = e
                                    ? `<span class="cart__subtotal-discounted text-sale"><span class="money">${r}</span></span> <span class="cart__subtotal-discounted text-muted text-strike"><span class="money">${a}</span></span>`
                                    : `<span class="money">${a}</span>`)
                        }
                    })
                }
                theme.cart
                    ? a()
                    : document.addEventListener('dbtfy:cartLoaded', a)
            }
            e(),
                $('body').on('ajaxCart.afterCartLoad', function (t, a, r) {
                    e(r)
                }),
                document.addEventListener('dbtfy:cartDiscountSaved', () => {
                    e()
                })
        }
        CartSavings()
    }
    function themeCartSavingsDestroy() {
        function destroyCartSavings() {
            const a = document.querySelectorAll('.cart_savings-wrapper')
            a.length &&
                a.forEach(a => {
                    a.remove()
                })
        }
        destroyCartSavings(),
            $('body').on('ajaxCart.afterCartLoad', function (a, r) {
                destroyCartSavings()
            })
    } /* end-dbtfy-cart-savings */

    /* start-dbtfy-cart-upsell */ function themeCartUpsell() {}
    function themeCartUpsellDestroy() {} /* end-dbtfy-cart-upsell */

    /* start-dbtfy-collection-addtocart */ function themeCollectionAddtocart() {
        async function CollectionAddtocart(e) {
            const t = document.querySelectorAll('.dbtfy-collection_addtocart')
            function n(e) {
                var t,
                    n,
                    d = $(e),
                    o = d.width(),
                    c = d.children(':selected').html(),
                    a =
                        o -
                        ((t = $(
                            `<span class="tempforSize">${c}</span>`
                        ).prependTo('body')),
                        (n = t.width()),
                        t.remove(),
                        n)
                d.css('text-align-last', ''), d.css('text-indent', a / 2 - 2)
            }
            t.length &&
                t.forEach(function (t) {
                    const d = t.querySelector('.ca-select'),
                        o = t.querySelector('.ca-button')
                    if (
                        (theme.isSafariBrowser() &&
                            d &&
                            (n(d),
                            window.addEventListener('resize', function () {
                                n(d)
                            }),
                            window.addEventListener(
                                'orientationchange',
                                function () {
                                    n(d)
                                }
                            )),
                        d && !d.hasAttribute('data-has-change-event'))
                    ) {
                        let e = document.querySelector(
                                '.ca-select-temp-generated'
                            ),
                            t = document.querySelector(
                                '.ca-select-temp-generated option'
                            )
                        e ||
                            ((e = document.createElement('select')),
                            (t = document.createElement('option')),
                            e.classList.add(
                                'ca-select-temp-generated',
                                'btn',
                                'btn--small',
                                'text-ellipsis'
                            ),
                            e.setAttribute('hidden', 'hidden'),
                            e.insertAdjacentElement('afterbegin', t),
                            document.body.insertAdjacentElement('beforeend', e),
                            (e = document.querySelector(
                                '.ca-select-temp-generated'
                            )),
                            (t = document.querySelector(
                                '.ca-select-temp-generated option'
                            ))),
                            e.removeAttribute('hidden'),
                            (t.innerHTML = d.options[0].value),
                            (d.style.width = `${e.offsetWidth}px`),
                            (o.style.width = `${e.offsetWidth}px`),
                            e.setAttribute('hidden', 'hidden'),
                            d.addEventListener('change', function () {
                                o.click()
                            })
                    }
                    o &&
                        !o.hasAttribute('data-has-click-event') &&
                        o.addEventListener('click', function () {
                            theme.loadingState.init(o)
                        }),
                        d &&
                            (d.classList.add('btn-outline-primary'),
                            d.classList.remove('btn--primary'),
                            (d.selectedIndex = 0),
                            d.setAttribute('data-has-change-event', 'true')),
                        o &&
                            (e && theme.loadingState.destroy(o),
                            o.setAttribute('data-has-click-event', 'true'))
                })
        }
        CollectionAddtocart(),
            $('body').on('ajaxCart.afterCartLoad', function (e) {
                CollectionAddtocart()
            }),
            $('body').on('ajaxCart.cartAddError', function (e) {
                CollectionAddtocart(!0)
            }),
            $('body').on('afterRecommendationLoad', function (e) {
                CollectionAddtocart()
            }),
            $(document).on('shopify:section:load', function (e) {
                CollectionAddtocart()
            }),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    CollectionAddtocart()
                }
            ),
            document.addEventListener('dbtfy:wishlistLoaded', () => {
                CollectionAddtocart()
            })
    }
    function themeCollectionAddtocartDestroy() {
        function destroyCollectionAddtocart() {
            const t = document.querySelectorAll('.dbtfy-collection_addtocart')
            t.length &&
                t.forEach(t => {
                    t.remove()
                })
        }
        document.body.classList.add('dbtfy-collection_addtocart-destroy'),
            destroyCollectionAddtocart(),
            $('body').on('ajaxCart.afterCartLoad', function (t, o) {
                destroyCollectionAddtocart()
            }),
            $('body').on('afterRecommendationLoad', function (t) {
                destroyCollectionAddtocart()
            }),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    destroyCollectionAddtocart()
                }
            )
    } /* end-dbtfy-collection-addtocart */

    /* start-dbtfy-collection-filters */ function themeCollectionFilters() {}
    function themeCollectionFiltersDestroy() {} /* end-dbtfy-collection-filters */

    /* start-dbtfy-color-swatches */ function themeColorSwatches() {
        function ColorSwatches() {
            const e = theme.strings
            document.querySelectorAll('.input-color_swatches').forEach(e => {
                e.hasAttribute('data-has-change-event') ||
                    (e.addEventListener('change', () => {
                        const t = e.closest('.custom_swatch_elements'),
                            r = t
                                ? t.querySelector('.label-color_swatches')
                                : null,
                            n = r ? r.getAttribute('data-label') : null,
                            o = e.dataset.sectionId,
                            a = document.getElementById(`AddToCartForm--${o}`)
                        if (!a) return
                        const c = e.dataset.index,
                            s = e.value
                        r &&
                            n &&
                            (r.innerHTML = `${n}: <span class="variant-label-option-value">${s}</span>`)
                        const l = a.querySelector(
                            `.single-option-selector__radio[data-index="option${c}"][value="${CSS.escape(
                                s
                            )}"]`
                        )
                        if (l)
                            return (
                                (l.checked = !0),
                                void l.dispatchEvent(new Event('change'))
                            )
                        const i = a.querySelector(
                            `select.single-option-selector__radio[data-index="option${c}"]`
                        )
                        i &&
                            ((i.value = s),
                            i.dispatchEvent(new Event('change')))
                    }),
                    e.setAttribute('data-has-change-event', 'true'))
            }),
                document
                    .querySelectorAll('.input-color_swatches--product-grid')
                    .forEach(t => {
                        t.addEventListener('change', () => {
                            const r = t.getAttribute('value'),
                                n = t.dataset.productId,
                                o = document
                                    .getElementById(`ProductSelect-${n}`)
                                    .querySelectorAll('option'),
                                a = Array.from(o).find(e => {
                                    const t = e.dataset.option1,
                                        n = e.dataset.option2,
                                        o = e.dataset.option3
                                    return t === r || n === r || o === r
                                })
                            if (!a) return
                            const c = a.getAttribute('value'),
                                s = a.dataset.image,
                                l = a.dataset.price,
                                i = a.dataset.compareAtPrice
                            document
                                .querySelectorAll(
                                    `.input-color_swatches--product-grid[data-product-id="${n}"][value="${r}"]`
                                )
                                .forEach(t => {
                                    const r = t.closest(
                                            '.grid-product, .list-product'
                                        ),
                                        n = r
                                            ? r.querySelector('.product--image')
                                            : null,
                                        o = r
                                            ? r.querySelector(
                                                  '.product-hover-image img'
                                              )
                                            : null,
                                        a = r
                                            ? r.querySelectorAll(
                                                  '.grid-product__image-link, .grid-product__meta, .list-product__image-link, .list-product__title-link'
                                              )
                                            : null,
                                        d = r
                                            ? r.querySelector(
                                                  '.grid-product__price-wrap, .list-product__price-wrap'
                                              )
                                            : null,
                                        u = d
                                            ? d.querySelector('.price-regular')
                                            : null,
                                        p = d
                                            ? d.querySelector('.price-compare')
                                            : null,
                                        m = r.querySelector(
                                            '.grid-product__badge, .list-product__badge'
                                        )
                                    if (
                                        (r && r.classList.add('cs-active'),
                                        n &&
                                            s &&
                                            (n.removeAttribute('srcset'),
                                            (n.src = s),
                                            o &&
                                                ((o.src = s),
                                                o.removeAttribute('srcset'))),
                                        a &&
                                            a.length &&
                                            a.forEach(e => {
                                                const t = e.getAttribute('href')
                                                e.href = `${
                                                    t.split('?variant=')[0]
                                                }?variant=${c}`
                                            }),
                                        (u || p) &&
                                            (u &&
                                                ((u.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                                    +l,
                                                    theme.strings.moneyFormat
                                                )}</span>`),
                                                i && p
                                                    ? (u.classList.add(
                                                          'on-sale',
                                                          'text-sale'
                                                      ),
                                                      u.setAttribute(
                                                          'aria-label',
                                                          e.salePrice
                                                      ))
                                                    : (u.classList.remove(
                                                          'on-sale',
                                                          'text-sale'
                                                      ),
                                                      u.setAttribute(
                                                          'aria-label',
                                                          e.regularPrice
                                                      ))),
                                            i && p
                                                ? (p.classList.remove('hide'),
                                                  (p.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                                      +i,
                                                      theme.strings.moneyFormat
                                                  )}</span>`))
                                                : (p.classList.add('hide'),
                                                  (p.innerHTML = ' '))),
                                        i && l)
                                    ) {
                                        if (i > l) {
                                            const e = m.querySelector(
                                                '.product-badge-discount'
                                            )
                                            if (
                                                (m.classList.remove('hide'),
                                                'amount' ===
                                                    theme.settings.saleType)
                                            ) {
                                                const t = i - l
                                                e &&
                                                    (e.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                                        +t,
                                                        theme.strings
                                                            .moneyFormat
                                                    )}</span>`)
                                            } else if (
                                                'percentage' ===
                                                theme.settings.saleType
                                            ) {
                                                const t = Math.floor(
                                                    (100 * (i - l)) / i
                                                )
                                                e && (e.innerText = `${t}%`)
                                            }
                                        }
                                    } else m.classList.add('hide')
                                    var h = $(
                                            "#currency-list-header[name='dbtfy-custom-currencies']"
                                        ),
                                        y = theme.strings.shopCurrency
                                    h.length &&
                                        y &&
                                        Currency.currentCurrency &&
                                        Currency.convertAll(
                                            y,
                                            Currency.currentCurrency
                                        )
                                })
                        })
                    })
        }
        ColorSwatches(),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    ColorSwatches()
                }
            ),
            $(document).on('shopify:section:load', function () {
                ColorSwatches()
            }),
            $('body').on('afterRecommendationLoad', function () {
                ColorSwatches()
            })
    }
    function themeColorSwatchesDestroy() {
        function destroyColorSwatches() {
            const o = document.querySelectorAll('.dbtfy-color_swatches'),
                e = document.querySelectorAll('.product-form__item')
            o.length &&
                o.forEach(o => {
                    o.remove()
                }),
                e.length &&
                    e.forEach(o => {
                        o.removeAttribute('hidden')
                    })
        }
        document.body.classList.add('dbtfy-color_swatches-destroy'),
            destroyColorSwatches(),
            $(document).on('shopify:section:load', function () {
                destroyColorSwatches()
            }),
            $('body').on('afterRecommendationLoad', function (o) {
                destroyColorSwatches()
            })
    } /* end-dbtfy-color-swatches */

    /* start-dbtfy-cookie-box */ function themeCookieBox() {
        async function CookieBox() {
            if (!document.querySelector('.dbtfy-cookie_box')) return
            const o = theme.settings.dbtfyCookieBoxEUOnly,
                e = await theme.getUserLocationData()
            if (!o || !e || e.in_eu) {
                var t = $('#CookieBox'),
                    i = $('.cb-btn, .cb-btn-close')
                localStorage.cookieboxClosed || t.show(),
                    i.click(function () {
                        theme.closeTransition(
                            { elementToTransition: t },
                            function () {
                                t.hide(),
                                    localStorage.setItem(
                                        'cookieboxClosed',
                                        'true'
                                    )
                            }
                        )
                    })
            }
        }
        CookieBox()
    }
    function themeCookieBoxDestroy() {
        function destroyCookieBox() {
            const o = document.querySelector('.dbtfy-cookie_box')
            o && o.remove()
        }
        document.body.classList.add('dbtfy-cookie_box-destroy'),
            destroyCookieBox()
    } /* end-dbtfy-cookie-box */

    /* start-dbtfy-custom-currencies */ function themeCustomCurrencies() {
        function CustomCurrencies(r) {
            var e = $("[name='dbtfy-custom-currencies']"),
                n = $("select[name='id']"),
                t = $('.Cart_currencyinfo'),
                c = theme.strings.shopCurrency,
                u = theme.strings.shopCurrency,
                o = sessionStorage.getItem('currencyCode')
            function a() {
                Currency.convertAll(c, e.val()),
                    o === c &&
                        $(
                            '#ajaxCartForm .Cart_currencyinfo, #cartForm .Cart_currencyinfo'
                        ).hide(),
                    $('.selected-currency').text(Currency.currentCurrency)
            }
            e &&
                ((Currency.format = theme.strings.currencyFormat),
                null == o
                    ? c !== u
                        ? Currency.convertAll(c, u, r)
                        : (Currency.currentCurrency = u)
                    : e.size() &&
                      0 ===
                          $(
                              `[name='dbtfy-custom-currencies'] option[value=${o}]`
                          ).size()
                    ? ((Currency.currentCurrency = c),
                      sessionStorage.setItem('currencyCode', c))
                    : o === c
                    ? ((Currency.currentCurrency = c),
                      Currency.convertAll(c, o, r),
                      t.hide())
                    : ((Currency.currentCurrency = o),
                      setTimeout(function () {
                          Currency.convertAll(c, o, r)
                      }, 1600)),
                e.val(Currency.currentCurrency).change(function () {
                    var r = $(this).val()
                    sessionStorage.setItem('currencyCode', r)
                }),
                setTimeout(function () {
                    n.length &&
                        n.each(function () {
                            $(this)
                                .find('option')
                                .each(function () {
                                    var r = $(this).data('variant-title'),
                                        e = parseFloat(
                                            $(this).data('variant-price')
                                        )
                                    if (!isNaN(e)) {
                                        var n = Currency.convert(
                                            e,
                                            c,
                                            Currency.currentCurrency
                                        )
                                        switch (theme.strings.currencyFormat) {
                                            case 'money_format':
                                                var t = Currency.formatMoney(
                                                    n,
                                                    Currency.moneyFormats[
                                                        Currency.currentCurrency
                                                    ].money_format
                                                )
                                                break
                                            case 'money_with_currency_format':
                                                t = Currency.formatMoney(
                                                    n,
                                                    Currency.moneyFormats[
                                                        Currency.currentCurrency
                                                    ].money_with_currency_format
                                                )
                                                break
                                            case 'money_without_trailing_zeros':
                                                t = Currency.formatMoney(
                                                    n,
                                                    Currency.moneyFormats[
                                                        Currency.currentCurrency
                                                    ]
                                                        .money_without_trailing_zeros
                                                )
                                                break
                                            case 'money_without_trailing_zeros_with_currency':
                                                t = Currency.formatMoney(
                                                    n,
                                                    Currency.moneyFormats[
                                                        Currency.currentCurrency
                                                    ]
                                                        .money_without_trailing_zeros_with_currency
                                                )
                                                break
                                            case 'money_without_sign_and_with_currency':
                                                t = Currency.formatMoney(
                                                    n,
                                                    Currency.moneyFormats[
                                                        Currency.currentCurrency
                                                    ]
                                                        .money_without_sign_and_with_currency
                                                )
                                                break
                                            case 'money_without_sign_and_with_decimal':
                                                t = Currency.formatMoney(
                                                    n,
                                                    Currency.moneyFormats[
                                                        Currency.currentCurrency
                                                    ]
                                                        .money_without_sign_and_with_decimal
                                                )
                                        }
                                        $(this).html(`${r} - ${t}`)
                                    }
                                })
                        })
                }, 1500),
                $('body').on('ajaxCart.afterCartLoad', function (r, e) {
                    a()
                    const n = document.querySelectorAll('.cart__subtotal'),
                        t = { attributes: !0, childList: !0, subtree: !0 }
                    n.forEach(r => {
                        new MutationObserver(a).observe(r, t)
                    })
                }),
                a())
        }
        $('body').on('dbtfy:cartUpsellLoaded', function (r, e) {
            CustomCurrencies()
        }),
            document.addEventListener('CartGoalLoaded', () => {
                CustomCurrencies('.cg-goal-amount span.money')
            }),
            document.addEventListener('QuickViewLoaded', () => {
                CustomCurrencies()
            }),
            document.addEventListener('dbtfy:upsellPopupMarkupLoaded', () => {
                CustomCurrencies()
            }),
            document.addEventListener('dbtfy:wishlistLoaded', () => {
                CustomCurrencies()
            }),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    CustomCurrencies()
                }
            ),
            CustomCurrencies()
    }
    function themeCustomCurrenciesDestroy() {
        function destroyCustomCurrencies() {
            const e = document.querySelectorAll('.dbtfy-custom_currencies'),
                r = document.querySelectorAll('.Cart_currencyinfo'),
                o = document.querySelector('#currency-list-header')
            e.length &&
                e.forEach(e => {
                    e.remove()
                }),
                r.length &&
                    r.forEach(e => {
                        e.remove()
                    }),
                o && (o.previousElementSibling.remove(), o.remove())
        }
        destroyCustomCurrencies(),
            $('body').on('ajaxCart.afterCartLoad', function (e, r) {
                destroyCustomCurrencies()
            })
    } /* end-dbtfy-custom-currencies */

    /* start-dbtfy-customizable-products */ function themeCustomizableProducts() {
        function CustomizableProducts() {
            const e = document.querySelector('.dbtfy-customizable_products')
            if (!e) return
            if (!e.querySelectorAll('.line-item-property__field').length) return
            const t = theme.visibilitySettings.sortVisibilityTypes(
                    e,
                    '.line-item-property__field'
                ),
                r = document.querySelectorAll(
                    '.featured-product-section, .product-section'
                )
            for (let [e, c] of t)
                c.forEach(e => {
                    const t = {
                        visibility: e.dataset.visibilityType,
                        tags: e.dataset.tags,
                        types: e.dataset.types,
                        productId: e.dataset.productId,
                        collection: e.dataset.collection,
                        template: e.cloneNode(!0),
                    }
                    r.forEach(e => {
                        const r = e.querySelector('.mainProductJson')
                        if (!r) return
                        const c = JSON.parse(r.innerHTML)
                        theme.visibilitySettings.checkForVisibility(
                            c,
                            t,
                            function () {
                                o(e, t)
                            }
                        )
                    })
                })
            function o(e, t) {
                const r = t.template.cloneNode(!0),
                    o = e.querySelector('.product-single__add-to-cart'),
                    c = e.querySelector('.dbtfy-list-property-fields')
                if (c) c.insertAdjacentElement('beforeend', r)
                else {
                    const e = document.createElement('div')
                    e.classList.add('dbtfy-list-property-fields'),
                        e.insertAdjacentElement('beforeend', r),
                        o.insertAdjacentElement('beforebegin', e)
                }
            }
            document
                .querySelectorAll('.dbtfy-list-property-fields')
                .forEach((e, t) => {
                    const r = e.querySelectorAll('.line-item-property__field')
                    r.forEach((e, r) => {
                        const o = e.querySelectorAll(
                                "input:not([type='hidden']), textarea, select"
                            ),
                            c = e.querySelectorAll(
                                '.cp-checkbox-group-checkbox'
                            ),
                            i = e.querySelector('.cp-checkbox-group-hidden')
                        c.forEach(e => {
                            e.addEventListener('change', () => {
                                const e = Array.from(c)
                                    .reduce(
                                        (e, t) =>
                                            t.checked ? `${e}, ${t.value}` : e,
                                        ''
                                    )
                                    .slice(2)
                                i.value = e
                            })
                        }),
                            o.forEach((e, o) => {
                                const c = e.parentElement.querySelector('label')
                                c.setAttribute('for', `${e.id}-${t}-${r}-${o}`),
                                    e.setAttribute(
                                        'id',
                                        `${e.id}-${t}-${r}-${o}`
                                    )
                            })
                    })
                })
        }
        CustomizableProducts()
    }
    function themeCustomizableProductsDestroy() {
        function destroyCustomizableProducts() {
            const o = document.querySelector('.dbtfy-customizable_products')
            o && o.remove()
        }
        destroyCustomizableProducts()
    } /* end-dbtfy-customizable-products */

    /* start-dbtfy-delivery-date */ function themeDeliveryDate() {}
    function themeDeliveryDateDestroy() {} /* end-dbtfy-delivery-date */

    /* start-dbtfy-delivery-time */ function themeDeliveryTime() {}
    function themeDeliveryTimeDestroy() {} /* end-dbtfy-delivery-time */

    /* start-dbtfy-discount-saved */ function themeDiscountSaved() {
        function DiscountSaved() {
            const e = document.querySelectorAll('.dbtfy-discount_saved')
            function t(e) {
                const t = e.querySelector("[id^='MainProductSelect']")
                if (!t) return
                e.querySelectorAll('.number-discount_saved').forEach(e => {
                    e.classList.add('hide')
                })
                const o = t.value
                if (!o) return
                const c = e.querySelector(`.discount_saved-${o}`)
                c && c.classList.remove('hide')
            }
            e.length &&
                e.forEach(e => {
                    const o = e.closest(
                        "[data-section-type='product-template']"
                    )
                    o &&
                        (t(o),
                        o
                            .querySelectorAll(
                                '.product-form__input, .single-option-selector__radio'
                            )
                            .forEach(e => {
                                e.addEventListener('change', () => {
                                    t(o)
                                })
                            }))
                })
        }
        DiscountSaved()
    }
    function themeDiscountSavedDestroy() {
        function destroyDiscountSaved() {
            const e = document.querySelectorAll('.dbtfy-discount_saved'),
                t = document.querySelectorAll(
                    '.dbtfy-sticky_addtocart .discount-sticky-price'
                )
            e.forEach(e => {
                e.remove()
            }),
                t.forEach(e => {
                    e.remove()
                })
        }
        destroyDiscountSaved()
    } /* end-dbtfy-discount-saved */

    /* start-dbtfy-facebook-messenger */ function themeFacebookMessengerDestroy() {} /* end-dbtfy-facebook-messenger */

    /* start-dbtfy-faq-page */ function themeFaqPage() {}
    function themeFaqPageDestroy() {} /* end-dbtfy-faq-page */

    /* start-dbtfy-gift-wrap */ function themeGiftWrap() {}
    function themeGiftWrapDestroy() {} /* end-dbtfy-gift-wrap */

    /* start-dbtfy-inactive-tab-message */ function themeInactiveTabMessage() {
        function InactiveTabMessage() {
            const e = document.title,
                t = theme.settings.dbtfyInactiveTabFirstMessage,
                n = theme.settings.dbtfyInactiveTabSecondMessage
                    ? theme.settings.dbtfyInactiveTabSecondMessage
                    : e,
                i = theme.decodeString(t),
                s = theme.decodeString(n),
                a = !!theme.settings.dbtfyInactiveTabSecondMessage,
                c = 1e3 * theme.settings.dbtfyInactiveTabDelay
            let d = !1,
                o = ''
            i &&
                document.addEventListener('visibilitychange', function (t) {
                    !document.hidden
                        ? (clearInterval(o), (document.title = e))
                        : a
                        ? (o = setInterval(function () {
                              ;(document.title = d ? s : i), (d = !d)
                          }, c))
                        : (document.title = i)
                })
        }
        InactiveTabMessage()
    } /* end-dbtfy-inactive-tab-message */

    /* start-dbtfy-infinite-scroll */ function themeInfiniteScroll() {
        function InfiniteScroll() {
            const n = document.querySelector('.dbtfy-infinite_scroll')
            n &&
                theme.loadScript(
                    theme.variables.ajaxinatePluginLink,
                    function () {
                        const e = n.dataset.endlessType
                        new Ajaxinate({
                            container: '#infiniteScrollContainer',
                            pagination: '#infiniteScrollPagination',
                            method: e,
                            callback: function () {
                                document.body.dispatchEvent(
                                    new CustomEvent('afterRecommendationLoad')
                                )
                            },
                        }),
                            document
                                .querySelectorAll('.pagination')
                                .forEach(n => {
                                    n.setAttribute('hidden', 'hidden')
                                })
                    }
                )
        }
        InfiniteScroll()
    }
    function themeInfiniteScrollDestroy() {
        function destroyInfiniteScroll() {
            const e = document.querySelector('.dbtfy-infinite_scroll')
            e &&
                (e.remove(),
                'undefined' != typeof Ajaxinate && new Ajaxinate().destroy())
        }
        destroyInfiniteScroll()
    } /* end-dbtfy-instagram-feed */

    /* start-dbtfy-instagram-feed */ function themeInstagramFeed() {}
    function themeInstagramFeedDestroy() {} /* end-dbtfy-instagram-feed */

    /* start-dbtfy-inventory-quantity */ function themeInventoryQuantity() {}
    function themeInventoryQuantityDestroy() {} /* end-dbtfy-inventory-quantity */

    /* start-dbtfy-linked-options */ function themeLinkedOptions() {} /* end-dbtfy-linked-options */

    /* start-dbtfy-live-view */ function themeLiveView() {
        function LiveView() {
            const e = document.querySelectorAll('.dbtfy-live_view')
            if (!e.length) return
            const t = sessionStorage.getItem('liveView'),
                i = theme.settings.dbtfyLiveViewMin,
                n = theme.settings.dbtfyLiveViewMax,
                s = theme.settings.dbtfyLiveViewIntervalSteps,
                o = theme.settings.dbtfyLiveViewIntervalTime
            function r(t) {
                e.forEach(e => {
                    const i = e.querySelector('.number-live_view')
                    i && (i.innerHTML = ` ${t}`)
                })
            }
            t && r(t),
                setInterval(function () {
                    const e = +sessionStorage.getItem('liveView'),
                        t = theme.getRandomNumberInRange(1, s),
                        o = e || theme.getRandomNumberInRange(i, n),
                        m = Math.random()
                    let v = 0,
                        a = 0
                    v =
                        m < 0.5
                            ? (a = o - t) > i
                                ? a
                                : i
                            : (a = o + t) < n
                            ? a
                            : n
                    sessionStorage.setItem('liveView', v), r(v)
                }, o)
        }
        LiveView()
    }
    function themeLiveViewDestroy() {
        function destroyLiveView() {
            const e = document.querySelectorAll('.dbtfy-live_view')
            if (
                (e.length &&
                    e.forEach(e => {
                        e.remove()
                    }),
                document.body.classList.contains('dbtfy-delivery_time-destroy'))
            ) {
                document
                    .querySelectorAll('.live-view-delivery-time-features')
                    .forEach(e => {
                        e.remove()
                    })
            }
            localStorage.removeItem('liveView')
        }
        destroyLiveView()
    } /* end-dbtfy-live-view */

    /* start-dbtfy-mega-menu */ function themeMegaMenu() {}
    function themeMegaMenuDestroy() {} /* end-dbtfy-mega-menu */

    /* start-dbtfy-menu-bar */ function themeMenuBarDestroy() {
        function destroyMenuBars() {
            const e = document.querySelectorAll('.dbtfy-menu_bar')
            e.length &&
                e.forEach(e => {
                    e.remove()
                })
        }
        destroyMenuBars()
    } /* end-dbtfy-menu-bar */

    /* start-dbtfy-newsletter-popup */ function themeNewsletterPopup() {}
    function themeNewsletterPopupDestroy() {} /* end-dbtfy-newsletter-popup */

    /* start-dbtfy-order-feedback */ function themeOrderFeedback() {}
    function themeOrderFeedbackDestroy() {} /* end-dbtfy-order-feedback */

    /* start-dbtfy-order-tracking */ function themeOrderTracking() {
        function OrderTracking() {
            const t = document.getElementById('ot-form'),
                e = document.getElementById('ot-num'),
                n = document.getElementById('ot-btn')
            t &&
                theme.loadScript(
                    theme.variables.trackingPluginLink,
                    function () {
                        t.addEventListener('submit', function (t) {
                            t.preventDefault()
                            const i = e.value
                            if ('' === i)
                                return (
                                    alert('Enter your number.'),
                                    theme.loadingState.destroy(n),
                                    void setTimeout(() => {
                                        n.removeAttribute('disabled')
                                    }, 0)
                                )
                            n.setAttribute('disabled', 'disabled'),
                                n.classList.add('btn--loading')
                            try {
                                !(function (t) {
                                    YQV5.trackSingle({
                                        YQ_ContainerId: 'ot-container',
                                        YQ_Height: 560,
                                        YQ_Fc: '0',
                                        YQ_Lang: 'en',
                                        YQ_Num: t,
                                    })
                                })(i)
                            } finally {
                                setTimeout(function () {
                                    n.removeAttribute('disabled'),
                                        n.classList.remove('btn--loading')
                                }, 1500)
                            }
                        })
                    }
                )
        }
        OrderTracking()
    }
    function themeOrderTrackingDestroy() {
        function destroyOrderTracking() {
            const r = document.querySelector('.dbtfy-order_tracking')
            r && r.remove()
        }
        destroyOrderTracking()
    } /* end-dbtfy-order-tracking */

    /* start-dbtfy-page-transition */ function themePageTransition() {}
    function themePageTransitionDestroy() {} /* end-dbtfy-page-transition */

    /* start-dbtfy-page-speed-booster */ function themePageSpeedBooster() {
        function PageSpeedBooster() {
            theme.loadScript(theme.variables.speedBoosterPluginLink)
        }
        PageSpeedBooster()
    } /* end-dbtfy-page-speed-booster */

    /* start-dbtfy-pricing-table */ function themePricingTableDestroy() {
        function destroyPricingTable() {
            const e = document.querySelectorAll('.dbtfy-pricing_table')
            e.length &&
                e.forEach(e => {
                    e.remove()
                })
        }
        destroyPricingTable()
    } /* end-dbtfy-pricing-table */

    /* start-dbtfy-product-bullet-points */ function themeProductBulletPoints() {
        function ProductBulletPoints() {
            const t = document.querySelector('.dbtfy-product_bullet_points')
            if (!t) return
            const e = t.querySelectorAll('.pbp-point')
            if (!e.length) return
            const i = document.querySelectorAll(
                    '.product-single[data-product-handle]'
                ),
                n = {}
            if (
                (i.length &&
                    i.forEach(t => {
                        const i = t.querySelector('.pbp-points'),
                            o = t.querySelector('.product-info-JSON'),
                            c = o ? JSON.parse(o.innerHTML) : null
                        if ((i && i.remove(), !c)) return
                        const r = c.handle
                        e.forEach(t => {
                            const e = {
                                visibility: t.dataset.visibilityType,
                                tags: t.dataset.visibilityProductTags,
                                types: t.dataset.visibilityProductTypes,
                                productId: t.dataset.visibilityProduct,
                                collection: t.dataset.visibilityCollection,
                            }
                            theme.visibilitySettings.checkForVisibility(
                                c,
                                e,
                                function () {
                                    !(function (t, e) {
                                        n[t]
                                            ? n[t].includes(e) || n[t].push(e)
                                            : (n[t] = [e])
                                    })(r, t)
                                }
                            )
                        })
                    }),
                !Object.keys(n).length)
            )
                return
            const o = t.dataset.position,
                c = t.dataset.alignment,
                r = t.dataset.displayType
            for (const [t, e] of Object.entries(n)) {
                const i = document.querySelector(
                    `.product-single[data-product-handle="${t}"]`
                )
                if (!i) return
                let n = null
                const s = []
                switch (o) {
                    case 'under-product-price':
                        n = i.querySelector('.price-container')
                        break
                    case 'under-addtocart-button':
                        n = i.querySelector('.product-single__add-to-cart')
                }
                if (!n) return
                const l = document.createElement('ul')
                l.classList.add('pbp-points', 'inline-list', ...s),
                    'left' === c
                        ? l.classList.add('text-left')
                        : l.classList.add('text-center'),
                    'newline' === r && (l.style.display = 'grid'),
                    e.forEach(t => {
                        l.insertAdjacentElement('beforeend', t.cloneNode(!0))
                    }),
                    n.insertAdjacentElement('afterend', l)
            }
        }
        ProductBulletPoints(),
            document.addEventListener('shopify:section:load', t => {
                ProductBulletPoints()
            })
    }
    function themeProductBulletPointsDestroy() {
        function destroyProductBulletPoints() {
            const t = document.querySelector('.dbtfy-product_bullet_points')
            t && t.remove()
        }
        destroyProductBulletPoints()
    } /* end-dbtfy-product-bullet-points */

    /* start-dbtfy-product-image-crop */ function themeProductImageCropDestroy() {
        document.body.classList.add('dbtfy-product_image_crop-destroy')
    } /* end-dbtfy-product-image-crop */

    /* start-dbtfy-product-swatches */ function themeProductSwatches() {
        function ProductSwatches() {
            const e = document.querySelector('.dbtfy-product_swatches')
            if (!e) return
            const t = e.dataset.showInProductGrid
            document
                .querySelectorAll('.product-section, .featured-product-section')
                .forEach(t => {
                    const c =
                            +t.querySelector('.product-single').dataset
                                .productId,
                        r = e.cloneNode(!0),
                        n = r.querySelectorAll('.ps-group'),
                        d = theme.settings.enableHistoryState
                    if ((o(n, c, r, t, !1), !d)) {
                        const e = window.location.pathname.split('?')
                        window.history.replaceState({}, '', e[0])
                    }
                })
            const c = document.querySelectorAll('.grid-product, .list-product')
            function o(e, t, c, o, r, n) {
                if (e.length) {
                    e.forEach(e => {
                        const c = e.querySelectorAll('li')
                        let o = !1
                        c.forEach(e => {
                            if (+e.dataset.productId === t) {
                                const t = e.dataset.productOption,
                                    c = e
                                        .closest('.ps-group')
                                        .querySelector(
                                            '.variant-label-option-value'
                                        )
                                ;(o = !0),
                                    e.classList.add('active'),
                                    c && (c.innerHTML = t)
                            }
                        }),
                            (!o || c.length <= 1) && e.remove()
                    }),
                        (o
                            ? o.querySelectorAll('.product_swatches_container')
                            : []
                        ).forEach(e => {
                            e.remove()
                        })
                    const d = o ? o.querySelector('.pbp-points') : null,
                        s = o ? o.querySelector('.price-container') : null,
                        a = document.createElement('div')
                    if (
                        (a.classList.add('product_swatches_container'),
                        a.insertAdjacentElement('afterbegin', c),
                        s)
                    ) {
                        s.nextElementSibling.classList.contains('pbp-points') &&
                        d
                            ? d.insertAdjacentElement('afterend', a)
                            : s.insertAdjacentElement('afterend', a)
                    }
                    ;(c.hidden = !1),
                        (a.hidden = !1),
                        r &&
                            (n
                                ? (n.insertAdjacentElement('afterend', a),
                                  c.classList.add('list-version'))
                                : (o &&
                                      o.insertAdjacentElement('afterbegin', a),
                                  c.classList.add('card-version')))
                }
            }
            'true' === t &&
                c.forEach(t => {
                    const c = t.classList.contains('empty-state'),
                        r = +t.dataset.productId,
                        n = t.querySelector(
                            '.grid-product__meta, .list-product__details-wrapper'
                        ),
                        d = t.querySelector('.list-product__price-wrap'),
                        s = e.cloneNode(!0),
                        a = s.querySelectorAll('.ps-group')
                    c || o(a, r, s, n, !0, d)
                })
        }
        ProductSwatches(),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    ProductSwatches()
                }
            ),
            document.addEventListener('shopify:section:load', e => {
                ProductSwatches()
            }),
            $('body').on('afterRecommendationLoad', function (e) {
                ProductSwatches()
            })
    }
    function themeProductSwatchesDestroy() {
        function destroyProductSwatches() {
            const e = document.querySelector('.dbtfy-product_swatches')
            e && e.remove()
        }
        destroyProductSwatches(),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    destroyProductSwatches()
                }
            )
    } /* end-dbtfy-product-swatches */

    /* start-dbtfy-product-tabs */ function themeProductTabs() {
        function ProductTabs() {
            const e = document.querySelector('.dbtfy-product_tabs')
            if (!e) return
            const t = e.dataset.displayType,
                o = theme.visibilitySettings.sortVisibilityTypes(
                    e,
                    '.product-tab'
                ),
                r = document.querySelectorAll(
                    '.featured-product-section, .product-section'
                ),
                c = 'vertical' === t
            if (c) {
                document.querySelectorAll('.horizontal-tab').forEach(e => {
                    e.remove()
                })
            } else {
                document.querySelectorAll('.vertical-tab').forEach(e => {
                    e.remove()
                })
            }
            document.querySelectorAll('.dbtfy-product-tab-box').forEach(e => {
                e.classList.remove('horizontal-tab-box')
            })
            for (let [e, t] of o)
                t.forEach(e => {
                    const t = {
                        visibility: e.dataset.visibilityType,
                        tags: e.dataset.productTags,
                        types: e.dataset.productTypes,
                        productId: e.dataset.product,
                        collection: e.dataset.productCollection,
                        template: e.cloneNode(!0),
                        blockType: e.dataset.blockType,
                    }
                    r.forEach(e => {
                        const o = e.querySelector('.mainProductJson')
                        if (!o) return
                        const r = JSON.parse(o.innerHTML)
                        theme.visibilitySettings.checkForVisibility(
                            r,
                            t,
                            function () {
                                i(e, t, r)
                            }
                        )
                    })
                })
            function a(e, t, o, r, c) {
                const a = r,
                    i = c
                if (
                    'description' === t.blockType &&
                    e.classList.contains('featured-product-section')
                ) {
                    const e = document.createElement('div')
                    e.classList.add('tab-content', 'rte'),
                        (e.innerHTML = `${o.description
                            .replace('<p>[split_description]</p>', '')
                            .replace('[split_description]', '')}`),
                        a.replaceChild(e, i)
                }
            }
            function i(t, o, r) {
                const i = o.template,
                    n = t.querySelectorAll('.dbtfy-product-tab-box'),
                    l = t.querySelector('.horizontal-tabs-body')
                if (c) {
                    const e = i.querySelector('.card-body'),
                        c = e.querySelector('.tab-content')
                    a(t, o, r, e, c)
                }
                n.forEach(n => {
                    const s = n.querySelector(`#${CSS.escape(i.id)}`)
                    ;(!!s && n.contains(s)) ||
                        n.insertAdjacentHTML('beforeend', i.outerHTML),
                        c
                            ? l && l.remove()
                            : (!(function (t, o, r, i) {
                                  const n = o.querySelectorAll('.product-tab'),
                                      l = document.createElement('div'),
                                      s = t.querySelector(
                                          '.horizontal-tabs-body'
                                      )
                                  l.classList.add('horizontal-tabs-body'),
                                      s ||
                                          o.insertAdjacentHTML(
                                              'afterend',
                                              l.outerHTML
                                          )
                                  n.forEach((o, n) => {
                                      const l = o.id,
                                          s = e.querySelector(
                                              `#ProductTabs [data-id="${l}"]`
                                          ),
                                          d = t.querySelector(
                                              '.horizontal-tabs-body'
                                          ),
                                          u = d.querySelector(
                                              `[data-id="${l}"]`
                                          )
                                      if (!u) {
                                          !(function (e, t, o, r) {
                                              if (c) return
                                              const a = r.querySelector(
                                                  '.horizontal-tab-active'
                                              )
                                              a &&
                                                  a.id !== o.dataset.id &&
                                                  (o.setAttribute(
                                                      'hidden',
                                                      'hidden'
                                                  ),
                                                  e.classList.remove(
                                                      'horizontal-tab-active'
                                                  ))
                                          })(o, 0, s, t)
                                          const e =
                                                  s.querySelector('.card-body'),
                                              n =
                                                  s.querySelector(
                                                      '.tab-content'
                                                  )
                                          a(t, r, i, e, n),
                                              d.insertAdjacentHTML(
                                                  'beforeend',
                                                  s.outerHTML
                                              )
                                      }
                                  }),
                                      o.classList.add('horizontal-tab-box')
                              })(t, n, o, r),
                              document
                                  .querySelectorAll(
                                      '.horizontal-tab-box .product-tab'
                                  )
                                  .forEach(e => {
                                      e.hasAttribute('has-click-event') ||
                                          (e.addEventListener('click', () => {
                                              const t = e.closest(
                                                      '.dbtfy-product-tab-box'
                                                  ),
                                                  o =
                                                      e.parentElement
                                                          .nextElementSibling,
                                                  r =
                                                      o.querySelectorAll(
                                                          '.tab'
                                                      ),
                                                  c = o.querySelector(
                                                      `[data-id='${e.id}']`
                                                  ),
                                                  a =
                                                      t.querySelectorAll(
                                                          '.product-tab'
                                                      )
                                              a.forEach(e => {
                                                  e.classList.remove(
                                                      'horizontal-tab-active'
                                                  )
                                              }),
                                                  r.forEach(e => {
                                                      e.setAttribute(
                                                          'hidden',
                                                          'hidden'
                                                      )
                                                  }),
                                                  c.removeAttribute('hidden'),
                                                  e.classList.add(
                                                      'horizontal-tab-active'
                                                  )
                                          }),
                                          e.setAttribute(
                                              'has-click-event',
                                              'true'
                                          ))
                                  }))
                }),
                    theme.tabs.init(),
                    e.querySelectorAll('.product-tab').forEach(e => {
                        e.remove()
                    })
            }
            r.forEach(e => {
                e.querySelectorAll('.product-tab').forEach(t => {
                    const o = t.dataset.blockType,
                        r = t.classList.contains('vertical-tab')
                    'reviews' === o &&
                        (function (e, t, o) {
                            const r = e.querySelector(
                                    '.product-single-reviews-wrapper .review-widget'
                                ),
                                c = e.querySelector('.review-badge'),
                                a = c ? c.cloneNode(!0) : null
                            let i, n
                            r && r.classList.remove('section-border')
                            if (o)
                                (i = t.querySelector('.tab-body .tab-content')),
                                    (n = t.querySelector('button'))
                            else {
                                const e = t.dataset.index
                                i =
                                    t.parentElement.nextElementSibling.querySelector(
                                        `[data-index='${e}'] .tab-body .tab-content`
                                    )
                            }
                            a && a.classList.remove('spacer-bottom')
                            const l = n && n.querySelector('.review-badge')
                            l && n && a && n.replaceChild(a, l)
                            i && r && i.insertAdjacentElement('afterbegin', r)
                        })(e, t, r)
                })
            })
        }
        ProductTabs(),
            document.addEventListener('shopify:section:load', e => {
                ProductTabs()
            })
    }
    function themeProductTabsDestroy() {
        function destroyProductTabs() {
            const t = document.querySelectorAll('.dbtfy-product_tabs')
            t.length &&
                t.forEach(t => {
                    t.remove()
                })
        }
        destroyProductTabs()
    } /* end-dbtfy-product-tabs */

    /* start-dbtfy-quantity-breaks */ function themeQuantityBreaks() {
        function QuantityBreaks() {
            const e = document.querySelector('.dbtfy-quantity_breaks')
            if (!e) return
            const t = {
                    singleBreakText: e.dataset.singleBreakText,
                    moreDiscountBeforeQuantityText:
                        e.dataset.moreDiscountBeforeQuantityText,
                    moreDiscountAfterQuantityText:
                        e.dataset.moreDiscountAfterQuantityText,
                    moreDiscountAfterPercentageText:
                        e.dataset.moreDiscountAfterPercentageText,
                    highestDiscountText: e.dataset.highestDiscountText,
                    singleBreakPriceType: e.dataset.singleBreakPriceType,
                    productSectionSelector:
                        "[data-section-type='product-template']",
                    cartBreakSelector: '.qb-cart_break',
                    productBreaksContainerSelector:
                        '.qb-product_breaks_container',
                    productBreaksSelector: '.qb-product_breaks',
                    productBreakSelector: '.qb-product_break',
                    quantitySelector: '.qb-quantity',
                    moneyFormat: theme.strings.moneyFormat,
                },
                r = {
                    add: function (e) {
                        const t = this.getStoredQuantityBreaks()
                        t.length
                            ? t.some(t => t.productId === e.productId) ||
                              (t.push(e),
                              sessionStorage.setItem(
                                  'breaks-discounts',
                                  JSON.stringify(t)
                              ))
                            : sessionStorage.setItem(
                                  'breaks-discounts',
                                  JSON.stringify([e])
                              )
                    },
                    getStoredQuantityBreaks: function () {
                        const e = sessionStorage.getItem('breaks-discounts')
                        if (!e) return []
                        const t = JSON.parse(e)
                        return 'object' != typeof t ? [] : t
                    },
                }
            function n() {
                const r = theme.cart
                r &&
                    (o(),
                    (function (r) {
                        const n = e.querySelector(t.cartBreakSelector)
                        if (!n) return
                        let o = r.item_count
                        const c = r.total_price,
                            a = +n.dataset.range,
                            i = n.dataset.discountCode,
                            u = +n.dataset.discountAmount,
                            d = n.dataset.discountType,
                            l = { name: i, type: d, amount: u }
                        0 === o
                            ? theme.discountCode.removeAllDiscounts(i, {
                                  role: 'cart-break',
                              })
                            : theme.discountCode.removeDiscount(i, {
                                  role: 'cart-break',
                              })
                        const y = theme.discountCode.getStoredDiscounts(),
                            m = s(
                                theme.discountCode.getMostValuableDiscount(
                                    y,
                                    r
                                ),
                                r
                            ),
                            p = s(l, r),
                            b = document.querySelector(
                                '#CartDrawer .cart__total-price-info'
                            ),
                            f = document.querySelector(
                                '#cartForm .cart__total-price-info'
                            ),
                            h = document.querySelector(
                                '#CartDrawer .qb-product_break_remains, #cartForm .qb-product_break_remains'
                            ),
                            q = theme.settings.dbtfyGiftWrap,
                            g = +n.dataset.giftWrapProductId,
                            S = r.items.some(e => e.product_id === g)
                        let v =
                            '<div class="dbtfy dbtfy-quantity_breaks qb-cart_break"><div class="card card-body-xs text-center qb-alert">'
                        q && S && o--
                        if (p > m) {
                            if (p <= c)
                                if (o < a) {
                                    const e = a - o
                                    let r = `${u}%`
                                    '$' === d &&
                                        (r = `<span class="money">${theme.Currency.formatMoney(
                                            100 * u,
                                            t.moneyFormat
                                        )}</span>`),
                                        (t.moreDiscountBeforeQuantityText ||
                                            t.moreDiscountAfterQuantityText ||
                                            t.moreDiscountAfterPercentageText) &&
                                            (v += `<div class="qb-text">${t.moreDiscountBeforeQuantityText} <strong class="text-secondary">${e}</strong> ${t.moreDiscountAfterQuantityText} <strong class="text-secondary">${r}</strong> ${t.moreDiscountAfterPercentageText}</div>`)
                                } else
                                    theme.discountCode.addDiscount(i, d, u, {
                                        role: 'cart-break',
                                    }),
                                        !h &&
                                            t.highestDiscountText &&
                                            (v += `<div class="qb-highest-discount-text">${t.highestDiscountText}</div>`)
                        } else
                            !h &&
                                t.highestDiscountText &&
                                m > 0 &&
                                (v += `<div class="qb-highest-discount-text">${t.highestDiscountText}</div>`)
                        v += '</div></div>'
                        const k = document.querySelector(
                            '.qb-alert-drawer-wrapper'
                        )
                        k && k.remove()
                        if (b) {
                            b.insertAdjacentHTML('afterend', v)
                            const e = document.querySelector(
                                '#CartDrawer .qb-cart_break'
                            )
                            if (e) {
                                const t = e.querySelector('.qb-alert')
                                t &&
                                    '' !== t.innerHTML.trim() &&
                                    e.classList.add(
                                        'qb-alert-drawer-wrapper',
                                        'spacer-top'
                                    )
                            }
                        }
                        const C = document.querySelector('.qb-alert-wrapper')
                        C && C.remove()
                        if (f) {
                            f.insertAdjacentHTML('afterend', v)
                            const e = document.querySelector(
                                '#cartForm .qb-cart_break'
                            )
                            if (e) {
                                const t = e.querySelector('.qb-alert')
                                t &&
                                    '' !== t.innerHTML.trim() &&
                                    e.classList.add(
                                        'qb-alert-wrapper',
                                        'spacer-top-sm',
                                        'spacer-bottom'
                                    )
                            }
                        }
                        const _ = $(
                                "#currency-list-header[name='dbtfy-custom-currencies']"
                            ),
                            A = theme.strings.shopCurrency
                        _.length &&
                            Currency.convertAll(
                                A,
                                Currency.currentCurrency,
                                '.qb-alert .money'
                            )
                        theme.discountCode.init()
                    })(r),
                    o())
            }
            function o() {
                !(function () {
                    const r = e.querySelectorAll(t.productBreaksSelector)
                    if (!r.length) return
                    const n = `\n            <div class="qb-product_breaks_container">\n              ${Array.from(
                        r
                    ).reduce((e, r) => {
                        const n = r.querySelectorAll(t.productBreakSelector)
                        if (!n.length) return e
                        const o = {
                                showOnProductPage: r.dataset.showOnProductPage,
                                visibilityType: r.dataset.visibilityType,
                                tags: r.dataset.tags,
                                types: r.dataset.types,
                                productId: r.dataset.productId,
                                collection: r.dataset.collection,
                            },
                            c = Array.from(n).reduce((e, r) => {
                                const n = r.dataset.range,
                                    o = r.dataset.discountCode,
                                    c = r.dataset.discountType,
                                    a = r.dataset.discountAmount
                                return (
                                    e +
                                    `\n                <div class="radio qb-quantity">\n                  <input type="radio"\n                         id="Quantity-break"\n                         name="quantity"\n                         value="${n}"\n                         data-discount-code="${o}"\n                         data-discount-type="${c}"\n                         data-discount-amount="${a}">\n\n                  <label for="Quantity-break">\n                    ${t.singleBreakText}\n                  </label>\n                </div>\n              `
                                )
                            }, ''),
                            a = `\n              <div class="qb-product_breaks"\n                   data-show-on-product-page="${o.showOnProductPage}"\n                   data-visibility-type="${o.visibilityType}"\n                   data-tags="${o.tags}"\n                   data-types="${o.types}"\n                   data-product-id="${o.productId}"\n                   data-collection="${o.collection}">\n\n                <div class="radio qb-quantity">\n                  <input type="radio"\n                         id="Quantity-break"\n                         name="quantity"\n                         value="1">\n\n                  <label for="Quantity-break">\n                    ${t.singleBreakText}\n                  </label>\n                </div>\n\n                ${c}\n              </div>\n            `
                        return e + a
                    }, '')}\n            </div>\n          `
                    e.insertAdjacentHTML('beforeend', n)
                })()
                const n = document.querySelectorAll(t.productSectionSelector)
                n.length &&
                    ((function (r) {
                        const n = theme.visibilitySettings.sortVisibilityTypes(
                            e,
                            t.productBreaksSelector
                        )
                        for (let [e, t] of n)
                            t.forEach(e => {
                                const t = {
                                    showOnProductPage:
                                        e.dataset.showOnProductPage,
                                    visibility: e.dataset.visibilityType,
                                    tags: e.dataset.tags,
                                    types: e.dataset.types,
                                    productId: e.dataset.productId,
                                    collection: e.dataset.collection,
                                    template: e.cloneNode(!0),
                                }
                                r.forEach(e => {
                                    const r =
                                        e.querySelector('.product-info-JSON')
                                    if (!r) return
                                    const n = JSON.parse(r.innerHTML)
                                    theme.visibilitySettings.checkForVisibility(
                                        n,
                                        t,
                                        function () {
                                            c(e, t)
                                        }
                                    )
                                })
                            })
                        const o = e.querySelector(
                            t.productBreaksContainerSelector
                        )
                        o && o.remove()
                    })(n),
                    document
                        .querySelectorAll(t.productBreaksSelector)
                        .forEach((e, r) => {
                            const n = e.querySelectorAll(t.quantitySelector)
                            n.forEach((e, t) => {
                                const n = e.querySelector('label'),
                                    o = e.querySelector('input')
                                n.setAttribute('for', `${o.id}-${r}-${t}`),
                                    o.setAttribute('id', `${o.id}-${r}-${t}`),
                                    0 === t &&
                                        o.setAttribute('checked', 'checked')
                            })
                        }),
                    (function () {
                        const e = document.querySelectorAll(
                                '.product-form__input, .single-option-radio, .input-color_swatches'
                            ),
                            t = document.querySelectorAll(
                                '.product-single__form--no-variants'
                            )
                        e.length &&
                            e.forEach(e => {
                                e.addEventListener('change', function (e) {
                                    a(e)
                                }),
                                    a({ target: e })
                            }),
                            t.forEach(e => {
                                a({ target: e })
                            })
                    })(),
                    (function (e) {
                        if (!e.length) return
                        e.forEach(e => {
                            const n = e.querySelector('.product-single__form')
                            n &&
                                n.addEventListener('submit', () => {
                                    !(function (e, n) {
                                        if (!e || !n) return
                                        const o = +e.dataset.productId,
                                            c = n.querySelectorAll(
                                                t.quantitySelector
                                            ),
                                            a = Array.from(c)
                                                .map(e => {
                                                    const t =
                                                            e.querySelector(
                                                                'input'
                                                            ),
                                                        r = +t.value,
                                                        n =
                                                            t.dataset
                                                                .discountCode,
                                                        o =
                                                            t.dataset
                                                                .discountType,
                                                        c =
                                                            +t.dataset
                                                                .discountAmount
                                                    if (1 !== r)
                                                        return {
                                                            breakCount: r,
                                                            discount: {
                                                                code: n,
                                                                type: o,
                                                                amount: c,
                                                            },
                                                        }
                                                })
                                                .filter(e => e),
                                            s = { productId: o, breaks: a }
                                        r.add(s)
                                    })(e, n)
                                })
                        })
                    })(n)),
                    (function () {
                        const e = theme.cart,
                            n = r.getStoredQuantityBreaks()
                        n.forEach(t => {
                            const r = t.productId,
                                n = t.breaks,
                                o = e.items.reduce(
                                    (e, t) =>
                                        t.product_id === r ? e + t.quantity : e,
                                    0
                                ),
                                c = n
                                    .slice()
                                    .reverse()
                                    .find(e => e.breakCount <= o)
                            c
                                ? theme.discountCode.removeAllDiscounts('', {
                                      productId: r,
                                      breakCount: {
                                          checkFunction: function (e) {
                                              return e.breakCount > c.breakCount
                                          },
                                      },
                                  })
                                : theme.discountCode.removeAllDiscounts('', {
                                      productId: r,
                                  })
                            const a = c ? c.discount : null,
                                i = theme.discountCode.getStoredDiscounts(),
                                u = theme.discountCode.getMostValuableDiscount(
                                    i,
                                    e
                                ),
                                d = s(u, e),
                                l = s(a, e),
                                y = e.total_price,
                                m = n.find(t => {
                                    const r = s(t.discount, e)
                                    return t.breakCount > o && r > d
                                })
                            if (l > d)
                                a &&
                                    l <= y &&
                                    theme.discountCode.addDiscount(
                                        a.code,
                                        a.type,
                                        a.amount,
                                        {
                                            role: 'quantity-breaks',
                                            productId: r,
                                            breakCount: c.breakCount,
                                        }
                                    )
                            else {
                                const e = a ? null : m
                                e &&
                                    theme.discountCode.removeDiscount(
                                        e.discount.code,
                                        {
                                            role: 'quantity-breaks',
                                            productId: r,
                                            breakCount: e.breakCount,
                                        }
                                    )
                            }
                            theme.discountCode.init()
                        }),
                            n.forEach(r => {
                                const n = r.productId,
                                    o = r.breaks,
                                    c = e.items.reduce(
                                        (e, t) =>
                                            t.product_id === n
                                                ? e + t.quantity
                                                : e,
                                        0
                                    ),
                                    a = o
                                        .slice()
                                        .reverse()
                                        .find(e => e.breakCount <= c),
                                    i = a ? a.discount : null,
                                    u = theme.discountCode.getStoredDiscounts(),
                                    d =
                                        theme.discountCode.getMostValuableDiscount(
                                            u,
                                            e
                                        ),
                                    l = s(d, e),
                                    y = s(i, e),
                                    m = e.total_price,
                                    p = o.find(t => {
                                        const r = s(t.discount, e)
                                        return t.breakCount > c && r > l
                                    }),
                                    b = p ? p.discount : null,
                                    f = s(b, e)
                                let h =
                                    '<div class="dbtfy dbtfy-quantity_breaks qb-product_break"><div class="card card-body-xs text-center qb-alert qb-product_break_remains">'
                                if (y > l) h += f > y && f <= m ? q() : g()
                                else {
                                    const t =
                                            theme.discountCode.getStoredDiscounts(),
                                        r =
                                            theme.discountCode.getMostValuableDiscount(
                                                t,
                                                e
                                            ),
                                        n = s(r, e)
                                    h += f > n && f <= m ? q() : g()
                                }
                                function q() {
                                    let e = ''
                                    const r = p.breakCount - c
                                    let n = `${b.amount}%`
                                    return (
                                        '$' === b.type &&
                                            (n = `<span class="money">${theme.Currency.formatMoney(
                                                100 * b.amount,
                                                t.moneyFormat
                                            )}</span>`),
                                        (t.moreDiscountBeforeQuantityText ||
                                            t.moreDiscountAfterQuantityText ||
                                            t.moreDiscountAfterPercentageText) &&
                                            (e += `<div class="qb-text">${t.moreDiscountBeforeQuantityText} <strong class="text-secondary">${r}</strong> ${t.moreDiscountAfterQuantityText} <strong class="text-secondary">${n}</strong> ${t.moreDiscountAfterPercentageText}</div>`),
                                        e
                                    )
                                }
                                function g() {
                                    return t.highestDiscountText && l > 0
                                        ? `<div class="qb-highest-discount-text">${t.highestDiscountText}</div>`
                                        : ''
                                }
                                ;(h += '</div></div>'),
                                    e.items.forEach(e => {
                                        const t = e.product_id
                                        if (t !== n) return
                                        const r = document.querySelectorAll(
                                            `.cart__row[data-product-id='${t}']`
                                        )
                                        r.forEach(e => {
                                            const t =
                                                    e.closest('.ajaxcart-form'),
                                                r = e.querySelectorAll(
                                                    '.dbtfy-quantity_breaks'
                                                )
                                            if (
                                                (r.forEach(e => {
                                                    e.remove()
                                                }),
                                                e.insertAdjacentHTML(
                                                    'afterbegin',
                                                    h
                                                ),
                                                t)
                                            ) {
                                                const t =
                                                    e.querySelector(
                                                        '.qb-product_break'
                                                    )
                                                t &&
                                                    t.classList.add(
                                                        'spacer-bottom'
                                                    )
                                            }
                                        })
                                    })
                                const S = $(
                                        "#currency-list-header[name='dbtfy-custom-currencies']"
                                    ),
                                    v = theme.strings.shopCurrency
                                S.length &&
                                    Currency.convertAll(
                                        v,
                                        Currency.currentCurrency,
                                        '.qb-alert .money'
                                    ),
                                    theme.discountCode.init()
                            })
                    })()
            }
            function c(e, r) {
                if (!e) return
                const n = e.querySelectorAll(t.productBreaksSelector),
                    o = document.querySelector('.quantity-sticky_addtocart')
                o && o.remove(),
                    n.forEach(e => {
                        e.remove()
                    })
                const c = e.querySelector('.product-single__quantity'),
                    a = c.querySelector('.qty-container')
                for (
                    c.querySelector('.inline-label').removeAttribute('hidden');
                    a.firstChild;

                )
                    a.removeChild(a.lastChild)
                const s = r.template.cloneNode(!0),
                    i = document.body.classList.contains('template-product')
                'true' !== s.dataset.showOnProductPage && i
                    ? a.setAttribute('hidden', 'hidden')
                    : a.removeAttribute('hidden'),
                    a.insertAdjacentElement('beforeend', s)
            }
            function a(e) {
                const r = e.target.closest(t.productSectionSelector)
                if (!r) return
                const n = r
                        .querySelector("[id^='MainProductSelect']")
                        .querySelector('option:checked'),
                    o = n ? +n.value : null,
                    c = r.querySelectorAll(t.quantitySelector),
                    a = r.querySelector('.mainProductJson')
                if (!a) return
                const s = JSON.parse(a.innerHTML).variants.find(e => e.id === o)
                if (s) {
                    c.forEach(e => {
                        const r = e.querySelector('label'),
                            n = e.querySelector('input'),
                            o = +n.value,
                            c = n.dataset.discountType,
                            a = +n.dataset.discountAmount,
                            i =
                                !!t.singleBreakPriceType &&
                                t.singleBreakPriceType.includes('single')
                        let u = s.price,
                            d = s.compare_at_price,
                            l = t.singleBreakText,
                            y = ''
                        if (((l = l.replace('_COUNT_', o)), o > 1))
                            switch (((d = i ? u : u * o), c)) {
                                case '$':
                                    i
                                        ? ((y = `<span class="money">${theme.Currency.formatMoney(
                                              (100 * a) / o,
                                              t.moneyFormat
                                          )}</span>`),
                                          (u = d - (100 * a) / o))
                                        : ((y = `<span class="money">${theme.Currency.formatMoney(
                                              100 * a,
                                              t.moneyFormat
                                          )}</span>`),
                                          (u = d - 100 * a))
                                    break
                                case '%':
                                    ;(u = d - (d / 100) * a), (y = `${a}%`)
                            }
                        if (
                            ((o > 1 && !a) || u < 0 || d < 0
                                ? e.setAttribute('data-invalid-value', 'true')
                                : e.removeAttribute('data-invalid-value'),
                            (l = d
                                ? l.replace(
                                      '_PRICE_',
                                      `\n                  <span class="qb-price text-money">\n                    <span class="qb-discounted-price on-sale text-sale">\n                      <span class="money">\n                        ${theme.Currency.formatMoney(
                                          u,
                                          t.moneyFormat
                                      )}\n                      </span>\n                    </span>\n\n                    <span class="qb-original-price text-strike text-muted">\n                      <span class="money">\n                        ${theme.Currency.formatMoney(
                                          d,
                                          t.moneyFormat
                                      )}\n                      </span>\n                    </span>\n                  </span>\n                `
                                  )
                                : l.replace(
                                      '_PRICE_',
                                      `\n                  <span class="qb-price text-money">\n                    <span class="money">\n                      ${theme.Currency.formatMoney(
                                          u,
                                          t.moneyFormat
                                      )}\n                    <span>\n                  </span>\n                `
                                  )),
                            o > 1)
                        ) {
                            const e = theme.strings.saveLabel.replace(
                                '{{ saved_amount }}',
                                y
                            )
                            l = l.replace(
                                '_SAVED_AMOUNT_',
                                `\n                  <span class="badge qb-saved-amount">\n                    ${e}\n                  </span>\n                `
                            )
                        } else l = l.replace('_SAVED_AMOUNT_', '')
                        r.innerHTML = l
                    }),
                        (function () {
                            theme.cart
                                ? e()
                                : document.addEventListener(
                                      'dbtfy:cartLoaded',
                                      e
                                  )
                            function e() {
                                const e = document.querySelectorAll(
                                    t.productBreaksSelector
                                )
                                e.forEach(e => {
                                    const n = e.querySelectorAll(
                                            t.quantitySelector
                                        ),
                                        o = e.closest(t.productSectionSelector)
                                    if (!o) return
                                    const c = o.querySelector(
                                            "[id^='MainProductSelect']"
                                        ),
                                        a = c.querySelector('option:checked'),
                                        s = +a.value,
                                        i = o.querySelector('.mainProductJson')
                                    if (!i) return
                                    const u = JSON.parse(i.innerHTML),
                                        d = u.variants.find(e => e.id === s)
                                    if (!d) return
                                    const l = theme.cart,
                                        y = +a.dataset.inventoryQuantity || 0,
                                        m = a.dataset.inventoryPolicy,
                                        p = l.items.reduce(
                                            (e, t) =>
                                                t.id === s ? e + t.quantity : e,
                                            0
                                        ),
                                        b = y - p
                                    d.available
                                        ? 'shopify' ===
                                              d.inventory_management &&
                                          'continue' !== m
                                            ? r(b, n)
                                            : r(30, n)
                                        : r(0, n)
                                })
                            }
                            function r(e, t) {
                                t.forEach(t => {
                                    const r = t.querySelector('input'),
                                        n = +r.value
                                    1 === n && (r.checked = !0),
                                        n > e ||
                                        t.hasAttribute('data-invalid-value')
                                            ? t.setAttribute('hidden', 'hidden')
                                            : t.removeAttribute('hidden')
                                })
                            }
                        })()
                    var i = $(
                            "#currency-list-header[name='dbtfy-custom-currencies']"
                        ),
                        u = theme.strings.shopCurrency
                    i.length &&
                        Currency.convertAll(
                            u,
                            Currency.currentCurrency,
                            '.qb-price .money, .qb-saved-amount .money'
                        )
                }
            }
            function s(e, t) {
                let r = 0
                if (e)
                    switch (e.type) {
                        case '$':
                            r = 100 * e.amount
                            break
                        case '%':
                            r = t.items_subtotal_price * (e.amount / 100)
                    }
                return r
            }
            !(function () {
                function e() {
                    theme.isCartLoaded
                        ? n()
                        : document.addEventListener('dbtfy:cartLoaded', () => {
                              n()
                          })
                }
                e(),
                    document.addEventListener('dbtfy:upsellPopupLoaded', () => {
                        e()
                    }),
                    $('body').on('ajaxCart.afterCartLoad', function (t, r) {
                        e()
                    })
            })(),
                $(document).on('shopify:section:load', function (e) {
                    'dbtfy-quantity-breaks' === e.detail.sectionId &&
                        sessionStorage.removeItem('breaks-discounts')
                })
        }
        QuantityBreaks(),
            document.addEventListener('shopify:section:load', e => {
                QuantityBreaks()
            })
    }
    function themeQuantityBreaksDestroy() {
        function destroyQuantityBreaks() {
            const t = document.querySelectorAll('.dbtfy-quantity_breaks'),
                e = document.querySelectorAll('.product-single__quantity')
            t.length &&
                t.forEach(t => {
                    t.remove()
                }),
                e.length &&
                    e.forEach(t => {
                        const e = t.hasAttribute('data-quantity-enabled'),
                            a = t.hasAttribute(
                                'data-inventory-quantity-enabled'
                            )
                        e || a || t.remove()
                    })
        }
        document.body.classList.add('dbtfy-quantity_breaks-destroy'),
            destroyQuantityBreaks()
    } /* end-dbtfy-quantity-breaks */

    /* start-dbtfy-quick-view */ function themeQuickView() {
        function QuickView() {
            if (!document.querySelector('.dbtfy-quick_view')) return
            const e = theme.strings.addToCart,
                t = theme.strings.unavailable,
                i = theme.strings.soldOut,
                a = theme.strings,
                n = document.getElementById('QuickViewContainer'),
                r = document.querySelectorAll('.qv-icon')
            r.length &&
                r.forEach(r => {
                    const s = r.hasAttribute('data-has-click-event')
                    s ||
                        (r.addEventListener('click', () => {
                            const s = r.dataset.productHandle
                            s &&
                                (theme.loadingState.init(r),
                                theme.fetchProductMarkup(
                                    {
                                        template: 'quick-view',
                                        productHandles: s,
                                    },
                                    c => {
                                        const o = c[s]
                                        for (; n.firstChild; )
                                            n.removeChild(n.firstChild)
                                        n.insertAdjacentHTML('afterbegin', o)
                                        const d = document.querySelector(
                                            `#QuickView-${s}`
                                        )
                                        d &&
                                            (theme.modal.init(),
                                            theme.modal.open(d),
                                            theme.loadingState.destroy(r),
                                            theme.ajaxCart.init(),
                                            theme.loadingState.initForm(),
                                            (function (e) {
                                                if (!e) return
                                                const t =
                                                    e.querySelector(
                                                        '.qv-slick-image'
                                                    )
                                                if (!t) return
                                                const i = e.querySelectorAll(
                                                        '.qv-slick-thumbnails'
                                                    ),
                                                    a =
                                                        e.querySelector(
                                                            '.qv-featured-image'
                                                        ),
                                                    n = a
                                                        ? +a.dataset.index
                                                        : 0,
                                                    r = {
                                                        slidesToShow: 5,
                                                        slidesToScroll: 1,
                                                        dots: !1,
                                                        arrows: !0,
                                                        focusOnSelect: !0,
                                                        swipeToSlide: !0,
                                                        infinite: !1,
                                                        speed: theme.variables
                                                            .transitionSpeed,
                                                        asNavFor: $(t),
                                                    },
                                                    s = {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        dots: !1,
                                                        arrows: !0,
                                                        focusOnSelect: !1,
                                                        swipeToSlide: !1,
                                                        infinite: !1,
                                                        speed: theme.variables
                                                            .transitionSpeed,
                                                        adaptiveHeight: !0,
                                                        asNavFor: $(i),
                                                    }
                                                theme.carousel.init({
                                                    slider: $(i),
                                                    slickOptions: r,
                                                }),
                                                    theme.carousel.init({
                                                        slider: $(t),
                                                        slickOptions: s,
                                                        goToSlide: n,
                                                    })
                                            })(d),
                                            (function (n) {
                                                const r =
                                                    n.querySelectorAll(
                                                        '.qv-select'
                                                    )
                                                if (!r.length) return
                                                const s =
                                                        n.querySelector(
                                                            '.qv-slick'
                                                        ),
                                                    c =
                                                        n.querySelector(
                                                            '.qv-master-select'
                                                        ),
                                                    o =
                                                        n.querySelector(
                                                            '.qv-price'
                                                        ),
                                                    d =
                                                        n.querySelector(
                                                            '.qv-price-compare'
                                                        ),
                                                    u = n.querySelector(
                                                        '.qv-quantity-wrapper'
                                                    ),
                                                    l = u
                                                        ? u.querySelector(
                                                              '.qty-input'
                                                          )
                                                        : null,
                                                    m = n.querySelector(
                                                        '.qv-addtocart-button'
                                                    ),
                                                    y = m.querySelector(
                                                        '.btn__add-to-cart-text'
                                                    ),
                                                    h =
                                                        n.querySelector(
                                                            '.qv-details'
                                                        )
                                                r.forEach(v => {
                                                    v.addEventListener(
                                                        'change',
                                                        () => {
                                                            theme.ProductQuantities.setMaxQuantity(
                                                                u,
                                                                c,
                                                                l
                                                            ),
                                                                theme.ProductQuantities.checkQuantity(
                                                                    u,
                                                                    c,
                                                                    l
                                                                )
                                                            let v = theme.cart
                                                            function q() {
                                                                const u =
                                                                        Array.from(
                                                                            r
                                                                        ).map(
                                                                            e =>
                                                                                e.value
                                                                        ),
                                                                    q =
                                                                        c.querySelector(
                                                                            `option[data-variant="${CSS.escape(
                                                                                u.join(
                                                                                    ''
                                                                                )
                                                                            )}"]`
                                                                        )
                                                                if (q) {
                                                                    const t =
                                                                            +q.value,
                                                                        r =
                                                                            q
                                                                                .dataset
                                                                                .available,
                                                                        c =
                                                                            +q
                                                                                .dataset
                                                                                .inventoryQuantity ||
                                                                            0,
                                                                        u =
                                                                            q
                                                                                .dataset
                                                                                .inventoryPolicy,
                                                                        b =
                                                                            q
                                                                                .dataset
                                                                                .inventoryManagement,
                                                                        S =
                                                                            v.items.reduce(
                                                                                (
                                                                                    e,
                                                                                    i
                                                                                ) =>
                                                                                    i.id ===
                                                                                    t
                                                                                        ? e +
                                                                                          i.quantity
                                                                                        : e,
                                                                                0
                                                                            ),
                                                                        k =
                                                                            q
                                                                                .dataset
                                                                                .imageId,
                                                                        L =
                                                                            +q
                                                                                .dataset
                                                                                .inventoryQuantity,
                                                                        Q =
                                                                            Boolean(
                                                                                q
                                                                                    .dataset
                                                                                    .inventoryQuantity
                                                                            ),
                                                                        g =
                                                                            q
                                                                                .dataset
                                                                                .price,
                                                                        w =
                                                                            q
                                                                                .dataset
                                                                                .priceCompare,
                                                                        C =
                                                                            n.querySelector(
                                                                                `.qv-image-${k}`
                                                                            ),
                                                                        A = C
                                                                            ? +C
                                                                                  .dataset
                                                                                  .slickIndex
                                                                            : null
                                                                    function f() {
                                                                        ;(y.innerHTML =
                                                                            e),
                                                                            m.classList.remove(
                                                                                'disabled'
                                                                            ),
                                                                            m.removeAttribute(
                                                                                'disabled'
                                                                            ),
                                                                            h.classList.remove(
                                                                                'variant-soldout'
                                                                            )
                                                                    }
                                                                    function p() {
                                                                        ;(y.innerHTML =
                                                                            i),
                                                                            m.classList.add(
                                                                                'disabled'
                                                                            ),
                                                                            m.setAttribute(
                                                                                'disabled',
                                                                                'disabled'
                                                                            ),
                                                                            h.classList.add(
                                                                                'variant-soldout'
                                                                            )
                                                                    }
                                                                    if (
                                                                        ((o.innerHTML = `<span class="money">${g}</span>`),
                                                                        h.classList.remove(
                                                                            'variant-unavailable'
                                                                        ),
                                                                        w
                                                                            ? (d.classList.remove(
                                                                                  'hide'
                                                                              ),
                                                                              (d.innerHTML = `<span class="money">${w}</span>`),
                                                                              o.setAttribute(
                                                                                  'aria-label',
                                                                                  a.salePrice
                                                                              ),
                                                                              o.classList.add(
                                                                                  'on-sale',
                                                                                  'text-sale'
                                                                              ))
                                                                            : (d.classList.add(
                                                                                  'hide'
                                                                              ),
                                                                              (d.innerHTML =
                                                                                  ' '),
                                                                              o.classList.remove(
                                                                                  'on-sale',
                                                                                  'text-sale'
                                                                              ),
                                                                              o.setAttribute(
                                                                                  'aria-label',
                                                                                  a.regularPrice
                                                                              )),
                                                                        (q.selected =
                                                                            !0),
                                                                        q.hasAttribute(
                                                                            'disabled'
                                                                        )
                                                                            ? p()
                                                                            : 'true' ===
                                                                              r
                                                                            ? 'shopify' ===
                                                                                  b &&
                                                                              'continue' !==
                                                                                  u
                                                                                ? S <
                                                                                  c
                                                                                    ? f()
                                                                                    : p()
                                                                                : f()
                                                                            : p(),
                                                                        k &&
                                                                            Number.isInteger(
                                                                                A
                                                                            ) &&
                                                                            s &&
                                                                            $(
                                                                                s
                                                                            ).slick(
                                                                                'slickGoTo',
                                                                                A
                                                                            ),
                                                                        l)
                                                                    ) {
                                                                        const e =
                                                                            +l.value
                                                                        Q &&
                                                                            L >=
                                                                                1 &&
                                                                            (l.setAttribute(
                                                                                'max',
                                                                                L
                                                                            ),
                                                                            (l.value =
                                                                                Math.min(
                                                                                    e,
                                                                                    L
                                                                                )))
                                                                    }
                                                                } else
                                                                    (y.innerHTML =
                                                                        t),
                                                                        m.classList.add(
                                                                            'disabled'
                                                                        ),
                                                                        m.setAttribute(
                                                                            'disabled',
                                                                            'disabled'
                                                                        ),
                                                                        h.classList.add(
                                                                            'variant-unavailable'
                                                                        )
                                                                var b = $(
                                                                        "#currency-list-header[name='dbtfy-custom-currencies']"
                                                                    ),
                                                                    S =
                                                                        theme
                                                                            .strings
                                                                            .shopCurrency
                                                                b.length &&
                                                                    (Currency.convertAll(
                                                                        S,
                                                                        Currency.currentCurrency,
                                                                        '.qv-price .money'
                                                                    ),
                                                                    Currency.convertAll(
                                                                        S,
                                                                        Currency.currentCurrency,
                                                                        '.qv-price-compare .money'
                                                                    ))
                                                            }
                                                            v
                                                                ? q()
                                                                : theme.isCartLoaded
                                                                ? ((v =
                                                                      theme.cart),
                                                                  q())
                                                                : document.addEventListener(
                                                                      'dbtfy:cartLoaded',
                                                                      () => {
                                                                          ;(v =
                                                                              theme.cart),
                                                                              q()
                                                                      }
                                                                  )
                                                        }
                                                    ),
                                                        v.dispatchEvent(
                                                            new Event('change')
                                                        )
                                                })
                                            })(d),
                                            (function (e) {
                                                const t = e.querySelector(
                                                    '.qv-quantity-wrapper'
                                                )
                                                if (!t) return
                                                const i =
                                                        e.querySelector(
                                                            '.qv-master-select'
                                                        ),
                                                    a =
                                                        t.querySelector(
                                                            '.qty-input'
                                                        )
                                                theme.ProductQuantities.setMaxQuantity(
                                                    t,
                                                    i,
                                                    a
                                                ),
                                                    theme.ProductQuantities.setVariantQuantities(
                                                        t,
                                                        i,
                                                        a
                                                    ),
                                                    theme.ProductQuantities.checkQuantity(
                                                        t,
                                                        i,
                                                        a
                                                    ),
                                                    t
                                                        .querySelectorAll(
                                                            'button'
                                                        )
                                                        .forEach(e => {
                                                            e.addEventListener(
                                                                'click',
                                                                e => {
                                                                    theme.ProductQuantities.checkQuantity(
                                                                        t,
                                                                        i,
                                                                        a
                                                                    )
                                                                }
                                                            )
                                                        }),
                                                    $('body').on(
                                                        'ajaxCart.afterCartLoad',
                                                        function () {
                                                            theme.ProductQuantities.setVariantQuantities(
                                                                t,
                                                                i,
                                                                a
                                                            )
                                                        }
                                                    ),
                                                    a &&
                                                        a.addEventListener(
                                                            'keyup',
                                                            function () {
                                                                theme.ProductQuantities.checkQuantity(
                                                                    t,
                                                                    i,
                                                                    a
                                                                )
                                                            }
                                                        ),
                                                    a &&
                                                        a.addEventListener(
                                                            'keydown',
                                                            function () {
                                                                theme.ProductQuantities.checkQuantity(
                                                                    t,
                                                                    i,
                                                                    a
                                                                )
                                                            }
                                                        ),
                                                    a &&
                                                        a.addEventListener(
                                                            'keypress',
                                                            function () {
                                                                theme.ProductQuantities.checkQuantity(
                                                                    t,
                                                                    i,
                                                                    a
                                                                )
                                                            }
                                                        )
                                            })(d),
                                            theme.productMediaZoom(),
                                            $('body').on(
                                                'ajaxCart.afterCartLoad',
                                                function (e, t) {
                                                    theme.modal.close(d)
                                                }
                                            ),
                                            document.dispatchEvent(
                                                new CustomEvent(
                                                    'QuickViewLoaded'
                                                )
                                            ))
                                    }
                                ))
                        }),
                        r.setAttribute('data-has-click-event', 'true'))
                })
        }
        QuickView(),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    QuickView()
                }
            ),
            $('body').on('afterRecommendationLoad', function (e) {
                QuickView()
            }),
            $(document).on('shopify:section:load', function (e) {
                QuickView()
            })
    }
    function themeQuickViewDestroy() {
        function destroyQuickView() {
            const e = document.querySelectorAll('.dbtfy-quick_view')
            e.length &&
                e.forEach(e => {
                    e.remove()
                })
        }
        document.body.classList.add('dbtfy-quick_view-destroy'),
            destroyQuickView(),
            $('body').on('afterRecommendationLoad', function (e) {
                destroyQuickView()
            })
    } /* end-dbtfy-quick-view */

    /* start-dbtfy-recently-viewed */ function themeRecentlyViewed() {
        async function RecentlyViewed() {
            const e = document.querySelector('.dbtfy-recently_viewed')
            if (!e) return
            const t = +e.dataset.productsLimit,
                r = e.dataset.rootUrl,
                n = r.length > 1 ? r : '',
                c = e.querySelector('.rv-floating-bar'),
                o = e.querySelector('.rv-floating-bar-products'),
                i = e.querySelector('.rv-section'),
                a = e.querySelector('.rv-section-products'),
                d = document.querySelector(
                    ".product-section [data-section-type='product-template']"
                ),
                u = d ? d.dataset.productHandle : '',
                l = p()
                    .filter(e => e !== u)
                    .slice(0, t),
                s = await (async function (e) {
                    if (!e || !e.length) return ''
                    const t = e.map(e =>
                        fetch(`${n}/products/${e}?view=recently-viewed`)
                    )
                    return await Promise.all(t)
                        .then(async e => Promise.all(e.map(e => e.text())))
                        .then(e => e)
                })(l),
                f = (function (e) {
                    if (!e || !e.length) return []
                    return e.map(e => {
                        const t = new DOMParser().parseFromString(
                                e,
                                'text/html'
                            ),
                            r = t.querySelector('.rv-floating-bar-product')
                        return r
                    })
                })(s)
            f.length &&
                (f.forEach(e => {
                    o && o.insertAdjacentElement('beforeend', e)
                }),
                c && c.removeAttribute('hidden'))
            const m = (function (e) {
                if (!e || !e.length) return []
                return e.map(e => {
                    const t = new DOMParser().parseFromString(e, 'text/html'),
                        r = t.querySelector('.rv-section-product .grid-product')
                    return r
                })
            })(s)
            function p() {
                const e = localStorage.getItem('recently-viewed-products')
                return e ? JSON.parse(e) : []
            }
            m.length &&
                (m.forEach(e => {
                    a && e && a.insertAdjacentElement('beforeend', e)
                }),
                i && i.removeAttribute('hidden'),
                theme.ProductGridSlider(e)),
                (function (e) {
                    if (!e) return
                    const t = p()
                    t.includes(e) || t.unshift(e)
                    localStorage.setItem(
                        'recently-viewed-products',
                        JSON.stringify(t)
                    )
                })(u),
                theme.ajaxCart.init(),
                document.dispatchEvent(
                    new CustomEvent('dbtfy:addedRecentlyViewedProduct')
                )
        }
        RecentlyViewed()
    }
    function themeRecentlyViewedDestroy() {
        function destroyRecentlyViewed() {
            const e = document.querySelectorAll('.dbtfy-recently_viewed')
            e.length &&
                e.forEach(e => {
                    e.remove()
                })
        }
        destroyRecentlyViewed()
    } /* end-dbtfy-recently-viewed */

    /* start-dbtfy-sales-countdown */ function themeSalesCountdown() {
        function SalesCountdown() {
            const t = document.querySelector('.dbtfy-sales-countdown-templates')
            if (!t) return
            const e = t.querySelectorAll('.dbtfy-sales_countdown'),
                o = document.querySelectorAll('.grid-product, .list-product'),
                n = document.querySelectorAll(
                    '.featured-product-section, .product-section'
                ),
                s = t.dataset.textDays,
                a = t.dataset.textHours,
                r = t.dataset.textMinutes,
                c = t.dataset.textSeconds,
                d = new Map()
            d.set('all', []),
                d.set('collection', []),
                d.set('type', []),
                d.set('tag', []),
                d.set('product', []),
                e.forEach(t => {
                    const e = t.dataset.visibility
                    if (d.has(e)) {
                        const o = d.get(e)
                        o.push(t), d.set(e, o)
                    }
                })
            for (let [t, e] of d)
                e.forEach(t => {
                    const e = {
                        action: t.dataset.action,
                        visibility: t.dataset.visibility,
                        showInProductGrid: t.dataset.showInProductGrid,
                        tags: t.dataset.tag,
                        types: t.dataset.type,
                        productId: t.dataset.product,
                        collection: t.dataset.collection,
                        template: t.cloneNode(!0),
                        countdownId: t.dataset.countdownId,
                        dataDate: t.dataset.date,
                        timeHours: +t.dataset.hours,
                        timeMins: +t.dataset.minutes,
                        timeSeconds: '59',
                    }
                    n.forEach(t => {
                        const o = t.querySelector('.mainProductJson')
                        o && i(JSON.parse(o.innerHTML), t, e)
                    }),
                        'true' === e.showInProductGrid &&
                            o.forEach(t => {
                                const o = t.querySelector('.productCardJson')
                                if (!o) return
                                const n = JSON.parse(o.innerHTML)
                                n && i(n, t, e)
                            }),
                        u(e)
                })
            function i(t, e, o) {
                if (t.price < t.compare_at_price) {
                    const n = t.collections,
                        s = n.length && n.some(t => t.handle === o.collection),
                        a = t.tags.map(t => t.toLowerCase()),
                        r = o.tags
                            .split(',')
                            .filter(t => t)
                            .map(t => t.trim().toLowerCase()),
                        c = a.some(t => r.includes(t)),
                        d = t.type.toLowerCase(),
                        i = o.types
                            .split(',')
                            .filter(t => t)
                            .map(t => t.trim().toLowerCase())
                    'all' === o.visibility
                        ? l(e, o, t)
                        : 'product' === o.visibility && t.id === +o.productId
                        ? l(e, o, t)
                        : 'collection' === o.visibility && s
                        ? l(e, o, t)
                        : 'tag' === o.visibility && c
                        ? l(e, o, t)
                        : 'type' === o.visibility && i.includes(d) && l(e, o, t)
                }
            }
            function l(t, e, o) {
                const n = e.template,
                    s = +o.first_available_variant_compare_at_price,
                    a = t.classList.contains('grid-product'),
                    r = t.classList.contains('list-product')
                ;('reload' !== e.action || e.timeHours || e.timeMins) &&
                    ((n.hidden = o.price > s),
                    t
                        .querySelectorAll('.dbtfy-sales_countdown')
                        .forEach(t => t.remove()),
                    t.classList.contains('empty-state') ||
                        (a || r
                            ? (n.classList.remove('text-small'),
                              e.showInProductGrid &&
                                  n.classList.add('text-small'),
                              r && n.classList.add('spacer-bottom'),
                              t
                                  .querySelector(
                                      '.grid-product__price-wrap, .list-product__price-wrap'
                                  )
                                  .insertAdjacentHTML('afterend', n.outerHTML))
                            : t
                                  .querySelectorAll(
                                      '.dbtfy-product-sales-countdown-box'
                                  )
                                  .forEach(t => {
                                      n.classList.remove('spacer-bottom-sm'),
                                          t.classList.contains(
                                              'main-product-block'
                                          ) &&
                                              n.classList.add(
                                                  'spacer-bottom-sm'
                                              ),
                                          t.insertAdjacentHTML(
                                              'beforeend',
                                              n.outerHTML
                                          )
                                  })))
            }
            function u(t) {
                const e = document.querySelectorAll('.dbtfy-sales_countdown'),
                    o = Array.from(e).filter(
                        e => e.dataset.countdownId === t.countdownId
                    )
                let n = Array.from(
                        document.querySelectorAll('.days-sales_countdown')
                    ).filter(e => e.closest(`.${t.countdownId}`)),
                    d = Array.from(
                        document.querySelectorAll('.hours-sales_countdown')
                    ).filter(e => e.closest(`.${t.countdownId}`)),
                    i = Array.from(
                        document.querySelectorAll('.minutes-sales_countdown')
                    ).filter(e => e.closest(`.${t.countdownId}`)),
                    l = Array.from(
                        document.querySelectorAll('.seconds-sales_countdown')
                    ).filter(e => e.closest(`.${t.countdownId}`)),
                    u = '',
                    m = ''
                function f() {
                    if ('' !== t.dataDate && 'reload' !== t.action)
                        u = `${t.dataDate} ${t.timeHours}:${t.timeMins}:${t.timeSeconds}`
                    else if (sessionStorage.hasOwnProperty(t.countdownId))
                        u = sessionStorage.getItem(t.countdownId)
                    else {
                        const e =
                            new Date().getTime() +
                            36e5 * t.timeHours +
                            6e4 * t.timeMins
                        u = new Date(e)
                    }
                    ;('' !== t.dataDate && 'reload' !== t.action) ||
                        sessionStorage.setItem(t.countdownId, u),
                        (m = new Date(u).getTime())
                }
                f()
                let p = setInterval(y, 1e3)
                function y() {
                    const e = new Date().getTime(),
                        u = m - e,
                        h = Math.floor(u / 864e5),
                        w = Math.floor((u % 864e5) / 36e5),
                        S = Math.floor((u % 36e5) / 6e4),
                        v = Math.floor((u % 6e4) / 1e3)
                    h <= 0
                        ? n.forEach(t => {
                              t.hidden = !0
                          })
                        : n.forEach(t => {
                              ;(t.textContent = h + s), (t.hidden = !1)
                          }),
                        h <= 0 && w <= 0
                            ? d.forEach(t => {
                                  t.hidden = !0
                              })
                            : d.forEach(t => {
                                  ;(t.textContent = w + a), (t.hidden = !1)
                              }),
                        h <= 0 && w <= 0 && S <= 0
                            ? i.forEach(t => {
                                  t.hidden = !0
                              })
                            : i.forEach(t => {
                                  ;(t.textContent = S + r), (t.hidden = !1)
                              }),
                        l.forEach(t => {
                            t.textContent = v + c
                        }),
                        u < 0 &&
                            (clearInterval(p),
                            'reload' === t.action
                                ? t.timeHours || t.timeMins
                                    ? (sessionStorage.removeItem(t.countdownId),
                                      f(),
                                      (p = setInterval(y, 1e3)),
                                      y())
                                    : o.forEach(t => {
                                          t.remove()
                                      })
                                : 'message' === t.action
                                ? o.forEach(t => {
                                      let e = t.querySelector(
                                          '.dbtfy-sales_countdown_message'
                                      )
                                      e
                                          ? ((e.hidden = !1),
                                            (t.querySelector(
                                                '.SalesCountdown'
                                            ).hidden = !0))
                                          : (t.hidden = !0)
                                  })
                                : o.forEach(t => {
                                      t.hidden = !0
                                  }))
                }
                y(), document.addEventListener('variantChanged', y)
            }
            var m = document.querySelectorAll('.single-option-selector__radio'),
                f = []
            m.forEach(t => {
                const e = t.closest(
                        "[data-section-type='product-template'], [data-section-type='quick-view-template']"
                    ),
                    o = e.dataset.sectionId,
                    n = e.querySelector(`#ProductJson-${o}`)
                n &&
                    ((f[o] = JSON.parse(n.innerHTML)),
                    t.addEventListener('change', () => {
                        let t = 0,
                            n = 0,
                            s = e.querySelector("[id^='MainProductSelect']"),
                            a = null,
                            r = !1,
                            c = +s.value
                        ;(f[o] ? f[o].variants : []).forEach(e => {
                            c === e.id &&
                                ((a = e),
                                (t = e.price),
                                (n = e.compare_at_price),
                                (r = e.available))
                        })
                        const d = e.querySelector('.dbtfy-sales_countdown')
                        d &&
                            (n && r
                                ? d.removeAttribute('hidden')
                                : d.setAttribute('hidden', 'hidden'),
                            document.dispatchEvent(
                                new CustomEvent('variantChanged')
                            ))
                    }))
            })
        }
        document.addEventListener('shopify:section:select', t => {
            for (const t in sessionStorage)
                t.includes('dbtfy-countdown') && sessionStorage.removeItem(t)
        }),
            SalesCountdown(),
            $('body').on('afterRecommendationLoad', function (t) {
                SalesCountdown()
            }),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    SalesCountdown()
                }
            ),
            $(document).on('shopify:section:load', function (t) {
                SalesCountdown()
            })
    }
    function themeSalesCountdownDestroy() {
        function destroySalesCountdown() {
            const o = document.querySelectorAll('.dbtfy-sales_countdown')
            o.length &&
                o.forEach(o => {
                    o.remove()
                })
        }
        destroySalesCountdown(),
            $('body').on('afterRecommendationLoad', function (o) {
                destroySalesCountdown()
            }),
            $(document).on('shopify:section:load', function () {
                destroySalesCountdown()
            })
    } /* end-dbtfy-sales-countdown */

    /* start-dbtfy-sales-pop */ function themeSalesPop() {}
    function themeSalesPopDestroy() {} /* end-dbtfy-sales-pop */

    /* start-dbtfy-shop-protect */ function themeShopProtect() {
        function ShopProtect() {
            var t = theme.settings.dbtfyShopProtectImage,
                e = theme.settings.dbtfyShopProtectDrag,
                o = theme.settings.dbtfyShopProtectTextProduct,
                n = theme.settings.dbtfyShopProtectTextArticle,
                c = theme.settings.dbtfyShopProtectCollection
            function i() {
                t &&
                    $('img').on('contextmenu', function (t) {
                        return !1
                    })
            }
            function d() {
                e &&
                    $('img').mousedown(function (t) {
                        t.preventDefault()
                    })
            }
            setTimeout(function () {
                i(), d()
            }, 1e3),
                o &&
                    $(
                        '.product-single__description, .qv-description, .tab-content'
                    ).addClass('dbtfy-shop_protect-text'),
                n &&
                    $('.article-section, .blog-section').addClass(
                        'dbtfy-shop_protect-text'
                    ),
                c &&
                    ($("#sortBy option[value='best-selling']").remove(),
                    '?sort_by=best-selling' === window.location.search &&
                        window.location.replace('/collections')),
                $('body').on('ajaxCart.afterCartLoad', function (t, e) {
                    i(), d()
                })
        }
        ShopProtect(),
            document.addEventListener('QuickViewLoaded', () => {
                ShopProtect()
            }),
            document.addEventListener(
                'dbtfy:addedRecentlyViewedProduct',
                () => {
                    ShopProtect()
                }
            )
    } /* end-dbtfy-shop-protect */

    /* start-dbtfy-size-chart */ function themeSizeChart() {}
    function themeSizeChartDestroy() {} /* end-dbtfy-size-chart */

    /* start-dbtfy-smart-search */ function themeSmartSearch() {}
    function themeSmartSearchDestroy() {} /* end-dbtfy-smart-search */

    /* start-dbtfy-social-discount */ function themeSocialDiscount() {
        function SocialDiscount() {
            var o = $('.dbtfy-social_discount'),
                t = $('.sd-btn'),
                e = $('#socialDiscountPopup'),
                i = $('.sd-social-sharing a'),
                s = $('#socialDiscountRewardPopup'),
                c = s.find('.sd-reward-apply'),
                n = $('.sd-loading'),
                a = $('.sd-body'),
                d = localStorage.getItem('DebutifySocialDiscount')
            if (o.length) {
                if ('Yes' === d) return t.hide(), void o.addClass('sd-used')
                i.on('click', function () {
                    a.hide(),
                        n.show(),
                        null ==
                            (d = localStorage.getItem(
                                'DebutifySocialDiscount'
                            )) &&
                            setTimeout(function () {
                                theme.modal.close(e), theme.modal.open(s)
                            }, 1e4)
                }),
                    c.on('click', function () {
                        const o = s[0].dataset.discountName,
                            t = s[0].dataset.discountType,
                            e = +s[0].dataset.discountAmount
                        o &&
                            t &&
                            e &&
                            (theme.discountCode.addDiscount(o, t, e),
                            theme.discountCode.init())
                    }),
                    s.on('modal.close', function () {
                        localStorage.setItem('DebutifySocialDiscount', 'Yes'),
                            t.hide(),
                            o.addClass('sd-used')
                    })
            }
        }
        $(document).on('shopify:section:load', function () {
            localStorage.removeItem('DebutifySocialDiscount')
        }),
            SocialDiscount()
    }
    function themeSocialDiscountDestroy() {
        function destroySocialDiscount() {
            const o = document.querySelectorAll('.dbtfy-social_discount')
            o.length &&
                o.forEach(o => {
                    o.remove()
                })
        }
        destroySocialDiscount(),
            $(document).on('shopify:section:load', function () {
                destroySocialDiscount()
            })
    } /* end-dbtfy-social-discount */

    /* start-dbtfy-sold-items */ function themeSoldItems() {}
    function themeSoldItemsDestroy() {} /* end-dbtfy-sold-items */

    /* start-dbtfy-sticky-addtocart */ function themeStickyAddtocart() {
        function StickyAddtocart() {
            const t = document.querySelector('.dbtfy-sticky_addtocart')
            if (!t) return
            const e = document.querySelectorAll(
                    '.single-option-selector__radio'
                ),
                n = document.querySelector("[id^='MainProductSelect']"),
                r = document.querySelector('.product-single__meta--wrapper')
            let c,
                i = t.dataset.position,
                a = t.querySelectorAll('.image-sticky_addtocart'),
                o = document.querySelector(
                    '.product-single__add-to-cart .btn--add-to-cart'
                ),
                s = t.querySelector('#stickyAddToCart'),
                u = t.querySelector('form'),
                d = t.querySelector('.btn--sticky_addtocart'),
                l = d.querySelector('.btn-text-sticky_addtocart'),
                y = o.offsetTop + o.offsetHeight,
                m = theme.strings.moneyFormat,
                p = theme.variables.animationDuration,
                h = 'top' === i ? null : 'scroll-sticky_addtocart',
                f = !1,
                S = document.querySelector('.quantity-sticky_addtocart'),
                k = t.querySelector('.select-sticky_addtocart')
            function v() {
                let e = t.querySelector('.total-sticky-price'),
                    n = t.querySelector('.total-sticky-compare-price'),
                    r = t.querySelector('.discount-sticky-price'),
                    c = t.querySelector('.discount-sticky-price-text'),
                    i = k.options[k.selectedIndex]
                if (!i) return
                let a = +i.dataset.price,
                    o = +i.dataset.compareAtPrice,
                    s = Boolean(
                        t.querySelector('.dbtfy-sticky-price-box').dataset
                            .discountSaved
                    )
                if (
                    ((e.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                        a,
                        m
                    )}</span>`),
                    a < o)
                ) {
                    if (
                        ((n.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                            o,
                            m
                        )}</span>`),
                        (n.style.display = 'inline-block'),
                        e.classList.add('text-sale'),
                        r && s)
                    ) {
                        let t = r.dataset.discountVariant,
                            e = (function (t, e, n) {
                                let r = null
                                if ('percentage' === n)
                                    r = `${Math.floor((100 * (e - t)) / e)}%`
                                else {
                                    let n = e - t
                                    r = theme.Currency.formatMoney(n, m)
                                }
                                return r
                            })(a, o, t)
                        const n =
                            'percentage' === t
                                ? e
                                : `<span class="money">${e}</span>`
                        ;(c.innerHTML = theme.strings.saveFormat.replace(
                            /\{\{ saved_amount \}\}/,
                            n
                        )),
                            (r.style.display = 'inline-block')
                    }
                } else
                    (n.style.display = 'none'),
                        e.classList.remove('text-sale'),
                        r && (r.style.display = 'none')
                var u = $(
                        "#currency-list-header[name='dbtfy-custom-currencies']"
                    ),
                    d = theme.strings.shopCurrency
                u.length &&
                    d &&
                    Currency.currentCurrency &&
                    Currency.convertAll(d, Currency.currentCurrency)
            }
            function b() {
                const t = k.selectedOptions[0]
                if (!t) return
                const e = document.querySelector(
                        '.product-single__meta--wrapper'
                    ),
                    n = t.hasAttribute('data-remain-qty')
                        ? +t.dataset.remainQty
                        : +t.dataset.productQty,
                    r = !e || !e.classList.contains('variant-soldout')
                n > 0 && r
                    ? (d.removeAttribute('disabled'),
                      (l.innerHTML = theme.strings.addToCart))
                    : (d.setAttribute('disabled', 'disabled'),
                      (l.innerHTML = theme.strings.soldOut)),
                    document.dispatchEvent(
                        new CustomEvent('stickyATCButtonStateChanged')
                    )
            }
            function Q(t) {
                a.forEach(t => {
                    t.classList.add('hide')
                }),
                    t &&
                        document
                            .querySelector(`.image-sticky_addtocart-${t}`)
                            .classList.remove('hide')
            }
            ;(window.updateStickyAtcVariant = !0),
                S && (c = S.querySelector('input')),
                window.addEventListener('scroll', () => {
                    window.pageYOffset > y
                        ? (s.classList.add('sa-visible'),
                          h && document.body.classList.add(h),
                          (f = !0))
                        : f &&
                          ((f = !1),
                          theme.closeTransition(
                              { elementToTransition: s, animationSpeed: p },
                              function () {
                                  s.classList.remove('sa-visible'),
                                      h && document.body.classList.remove(h)
                              }
                          ))
                }),
                u.addEventListener('submit', t => {
                    o && o.click()
                    const e = document.querySelector(
                        '.dbtfy-list-property-fields'
                    )
                    if (!e) return
                    const n = e.querySelectorAll('[required]')
                    if (!n) return
                    const r = Array.from(n).find(t => {
                        return ('checkbox' !== t.getAttribute('type') ||
                            t.checked) &&
                            t.value
                            ? void 0
                            : t
                    })
                    return (
                        t.preventDefault(),
                        t.stopImmediatePropagation(),
                        r &&
                            setTimeout(() => {
                                theme.loadingState.destroy(d)
                            }, 0),
                        !1
                    )
                }),
                theme.ProductQuantities.setMaxQuantity(S, k, c),
                theme.ProductQuantities.setVariantQuantities(S, k, c),
                theme.ProductQuantities.syncQuantityInputs(c),
                theme.ProductQuantities.checkQuantity(S, k, c),
                v(),
                b(),
                S &&
                    S.querySelectorAll('button').forEach(t => {
                        t.addEventListener('click', t => {
                            theme.ProductQuantities.checkQuantity(S, k, c),
                                theme.ProductQuantities.syncQuantityInputs(c)
                        })
                    }),
                k &&
                    k.addEventListener('change', async e => {
                        let n = e.target,
                            r = n.value,
                            i = n.options[n.selectedIndex].dataset.price
                        Q(r),
                            theme.ProductQuantities.setMaxQuantity(S, k, c),
                            theme.ProductQuantities.checkQuantity(S, k, c),
                            theme.ProductQuantities.syncQuantityInputs(c),
                            b(),
                            (t.querySelector(
                                '.total-sticky-price'
                            ).innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                i,
                                m
                            )}</span>`),
                            v()
                        let a = document.querySelector(
                                "#currency-list-header[name='dbtfy-custom-currencies']"
                            ),
                            o = theme.strings.shopCurrency
                        a &&
                            Currency.convertAll(
                                o,
                                Currency.currentCurrency,
                                '.dbtfy-sticky-price-box .money'
                            ),
                            window.updateStickyAtcVariant &&
                                theme.syncedProductVariant(n, 'sticky')
                    }),
                e.forEach(t =>
                    t.addEventListener('change', t => {
                        if (!window.updateStickyAtcVariant) return
                        let e = k.querySelector(`option[value="${n.value}"]`)
                        !r.classList.contains('variant-unavailable') &&
                            e &&
                            ((k.value = n.value), Q(k.value), b(), v())
                    })
                ),
                $('body').on('hideStickyAtcOnUnavailableButton', function () {
                    s && s.setAttribute('hidden', 'hidden')
                }),
                $('body').on('productQuantitySync', function () {
                    theme.ProductQuantities.checkQuantity(S, k, c)
                }),
                $('body').on('ajaxCart.afterCartLoad', function () {
                    theme.ProductQuantities.setVariantQuantities(S, k, c),
                        v(),
                        b()
                }),
                $('body').on('updatedProductRemainingQuantities', function () {
                    b()
                }),
                c &&
                    c.addEventListener('keydown', function (t) {
                        const e = t.code
                        ;('ArrowUp' === e ||
                            'ArrowDown' === e ||
                            e.includes('Digit')) &&
                            setTimeout(() => {
                                theme.ProductQuantities.checkQuantity(S, k, c),
                                    theme.ProductQuantities.syncQuantityInputs(
                                        c
                                    )
                            })
                    }),
                document.addEventListener('productInfoLoaded', () => {
                    b()
                })
        }
        StickyAddtocart()
    }
    function themeStickyAddtocartDestroy() {
        function destroyStickyAddtocart() {
            const t = document.querySelectorAll('.dbtfy-sticky_addtocart')
            t.length &&
                t.forEach(t => {
                    t.remove()
                })
        }
        document.body.classList.add('dbtfy-sticky_addtocart-destroy'),
            destroyStickyAddtocart()
    } /* end-dbtfy-sticky-addtocart */

    /* start-dbtfy-synced-variant-image */ function themeSyncedVariantImage() {
        function SyncedVariantImage() {
            const e = document.querySelectorAll(
                "[data-section-type='product-template'], [data-section-type='quick-view-template']"
            )
            e.length &&
                e.forEach(e => {
                    const t = e.querySelectorAll('.product-single__thumb')
                    if (t.length > 1) {
                        const n = e.dataset.sectionId,
                            o = e.querySelector(
                                "[id^='ProductJson-'], .mainProductJson"
                            ),
                            i = o ? JSON.parse(o.innerHTML) : null
                        if (!i) return
                        const a = $(e).find(
                            '.product-single__media-group-wrapper .slick'
                        )
                        if (i.variants.length > 1) {
                            let o = null
                            a.on('beforeChange', function (e, t, n, i) {
                                o = i
                            }),
                                a.on('afterChange', function (a, c, r) {
                                    if (o !== r) return
                                    o = 0
                                    const l = (function (e, t, n) {
                                        const o = n.querySelector(
                                                '.product-single__variants, .qv-master-select'
                                            ),
                                            i = o.options[o.selectedIndex]
                                        let a = []
                                        t.variants.forEach(t => {
                                            t.featured_media &&
                                                t.featured_media.id &&
                                                t.featured_media.id === e &&
                                                a.push(t)
                                        })
                                        let c = 3
                                        for (; c > 0; ) {
                                            const e = i.dataset[`option-${c}`]
                                            if ('' !== e) {
                                                const t = a.filter(t =>
                                                    Object.values(t).includes(e)
                                                )
                                                a = t.length ? t : a
                                            }
                                            c--
                                        }
                                        return a.shift()
                                    })(
                                        +t[r]
                                            .closest(
                                                '.product-single__thumbnail'
                                            )
                                            .dataset.mediaId.replace(
                                                `${n}-`,
                                                ''
                                            ),
                                        i,
                                        e
                                    )
                                    var s
                                    l &&
                                        (function (e, t) {
                                            const n = t.featured_media.id,
                                                o = e.querySelector(
                                                    `.qv-image-${n}`
                                                ),
                                                i = o
                                                    ? +o.dataset.slickIndex
                                                    : null,
                                                a = e.querySelector('.qv-slick')
                                            n &&
                                                Number.isInteger(i) &&
                                                a &&
                                                $(a).slick('slickGoTo', i)
                                            ;[
                                                t.option1,
                                                t.option2,
                                                t.option3,
                                            ].forEach((t, n) => {
                                                const o = e.querySelectorAll(
                                                    `.single-option-selector__radio[name='option${
                                                        n + 1
                                                    }'][value='${t}'], .single-option-selector__radio[name='option${
                                                        n + 1
                                                    }'] option[value='${t}'], .input-color_swatches[data-option-position='option${
                                                        n + 1
                                                    }'][value='${t}']`
                                                )
                                                o.forEach(e => {
                                                    const n = e.closest(
                                                        '.single-option-selector__radio'
                                                    )
                                                    ;(e.checked = !0),
                                                        (e.value = t),
                                                        n &&
                                                            ((n.value = t),
                                                            n.dispatchEvent(
                                                                new Event(
                                                                    'change'
                                                                )
                                                            )),
                                                        e.dispatchEvent(
                                                            new Event('change')
                                                        )
                                                })
                                            })
                                        })(e, l),
                                        (s =
                                            document.querySelectorAll(
                                                'iframe'
                                            )),
                                        Array.prototype.forEach.call(s, e => {
                                            e.contentWindow.postMessage(
                                                JSON.stringify({
                                                    event: 'command',
                                                    func: 'stopVideo',
                                                }),
                                                '*'
                                            )
                                        })
                                })
                        }
                    }
                })
        }
        $(document).on('QuickViewLoaded', function (e) {
            SyncedVariantImage()
        }),
            SyncedVariantImage()
    } /* end-dbtfy-synced-variant-image */

    /* start-dbtfy-trust-badge */ function themeTrustBadgeDestroy() {
        function destroyTrustBadge() {
            const e = document.querySelectorAll('.dbtfy-trust_badge')
            e.length &&
                e.forEach(e => {
                    e.remove()
                })
        }
        destroyTrustBadge()
    } /* end-dbtfy-trust-badge */

    /* start-dbtfy-upsell-bundles */ function themeUpsellBundles() {
        function UpsellBundles() {
            if (Shopify.designMode) {
                const e = document.querySelectorAll('.dbtfy-upsell_bundles')
                if (e.length > 1) {
                    const e = document.querySelector('.product-single'),
                        t = e.querySelector('.dbtfy-upsell_bundles')
                    t.remove()
                }
            }
            const dbtfy = document.querySelector('.dbtfy-upsell_bundles')
            if (!dbtfy) return
            const $container = document.querySelector('#UpsellBundles'),
                sectionId = $container.dataset.sectionId,
                productId = $container.dataset.productId,
                limit = +$container.dataset.limit,
                recommendationsSectionUrl = `/recommendations/products?&section_id=${sectionId}&product_id=${productId}&limit=${limit}`,
                discountName = $container.dataset.discountName,
                discountAmount = $container.dataset.discountAmount,
                discountType = $container.dataset.discountType,
                position = $container.dataset.position
            function upsellBundleLoaded() {
                const $buttonBundle = document.querySelector(
                        '.btn-upsell_bundles'
                    ),
                    $bundleSelect = document.querySelectorAll(
                        '.select-upsell_bundles'
                    ),
                    bundleTotal = document.querySelector(
                        '.total-upsell_bundles'
                    ),
                    bundleCompareTotal = document.querySelector(
                        '.compare-total-upsell_bundles'
                    ),
                    discountBox = document.querySelector(
                        '.discount-upsell_bundles'
                    ),
                    discountBoxText = document.querySelector(
                        '.discount-upsell_bundles_text'
                    ),
                    discountVariant = document.querySelector(
                        '.total-wrapper-upsell_bundles'
                    ).dataset.discountVariant,
                    moneyFormat = theme.strings.moneyFormat,
                    imageWrapperBox = document.querySelector(
                        '.image-wrapper-upsell_bundles'
                    ),
                    currentImageUpsell = document.querySelector(
                        '.current-image-upsell_bundles'
                    ),
                    formWrapperBox = document.querySelector(
                        '.form-wrapper-upsell_bundles'
                    ),
                    currentFormUpsell = document.querySelector(
                        '.current-form-upsell_bundles'
                    ),
                    listCheckboxes = document.querySelectorAll(
                        '.checkbox-upsell_bundles'
                    ),
                    listVariantSelects = document.querySelectorAll(
                        '.single-option-selector__radio'
                    ),
                    masterSelect = document.querySelector(
                        "[id^='MainProductSelect']"
                    ),
                    productTemplateMetaContainer = document.querySelector(
                        '.product-single__meta--wrapper'
                    )
                if (((window.updateBundleVariant = !0), !currentImageUpsell))
                    return void dbtfy.setAttribute('hidden', 'hidden')
                function addUpsellToProductSection() {
                    const e = document.querySelector('.dbtfy-trust_badge')
                    if (e) e.insertAdjacentElement('afterend', dbtfy)
                    else {
                        document
                            .querySelector('.btn--add-to-cart')
                            .closest('form')
                            .insertAdjacentElement('afterend', dbtfy)
                    }
                }
                imageWrapperBox.insertAdjacentElement(
                    'afterbegin',
                    currentImageUpsell
                ),
                    formWrapperBox.insertAdjacentElement(
                        'afterbegin',
                        currentFormUpsell
                    ),
                    'button' === position && addUpsellToProductSection(),
                    listCheckboxes.forEach(e =>
                        e.addEventListener('click', t => {
                            const n = e.dataset.productId,
                                o = e.dataset.index,
                                s = document.querySelector(
                                    `.grid-image-${n}-${o}`
                                ),
                                l = document.querySelector(
                                    `#upsellBundles-${n}-${o}`
                                )
                            s.classList.toggle('selected-image-upsell_bundles'),
                                l.classList.toggle('active-upsell_bundles'),
                                verifyUnchecked(),
                                checkPrice()
                        })
                    ),
                    $bundleSelect.forEach(e =>
                        e.addEventListener('change', t => {
                            const n = e.value,
                                o = e.dataset.index,
                                s = document.querySelector(
                                    `#imageUpsellBundles-${n}-${o}`
                                ),
                                l = s
                                    .closest('.image-item-upsell_bundles')
                                    .querySelectorAll(
                                        '.image-link-upsell_bundles'
                                    ),
                                r = e.options[e.selectedIndex],
                                c = e.nextElementSibling.querySelector(
                                    '.dbtfy-upsell-bundle-current-price'
                                ),
                                u = e.nextElementSibling.querySelector(
                                    '.dbtfy-upsell-bundle-compare-price'
                                ),
                                a = +r.dataset.price,
                                d = +r.dataset.compareAtPrice
                            l.forEach(e => (e.style.display = 'none')),
                                s && (s.style.display = 'block'),
                                a > d
                                    ? (c.classList.remove('text-sale'),
                                      u && (u.style.display = 'none'))
                                    : (c.classList.add('text-sale'),
                                      u && (u.style.display = 'inline-block')),
                                c &&
                                    ((c.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                        a,
                                        moneyFormat
                                    )}</span>`),
                                    (u.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                        d,
                                        moneyFormat
                                    )}</span>`)),
                                checkPrice(),
                                e.closest('.current-form-upsell_bundles') &&
                                    window.updateBundleVariant &&
                                    theme.syncedProductVariant(e, 'bundle')
                        })
                    ),
                    listVariantSelects.forEach(e =>
                        e.addEventListener('change', e => {
                            if (!window.updateBundleVariant) return
                            const t = currentFormUpsell.querySelector(
                                    '.select-upsell_bundles'
                                ),
                                n = t.querySelector(
                                    `option[value="${masterSelect.value}"]`
                                )
                            !productTemplateMetaContainer.classList.contains(
                                'variant-unavailable'
                            ) &&
                                n &&
                                ((t.value = masterSelect.value),
                                t.dispatchEvent(new Event('change')))
                        })
                    ),
                    checkPrice(),
                    $buttonBundle.addEventListener('click', e => {
                        const forms = document.querySelectorAll(
                                '.form-upsell_bundles.active-upsell_bundles'
                            ),
                            cartType = theme.settings.cartType,
                            boxWithErrors = dbtfy.querySelector('.errors')
                        boxWithErrors && boxWithErrors.remove()
                        const items = []
                        forms.forEach(e => {
                            const t = +e.querySelector('.select-upsell_bundles')
                                    .value,
                                n = items.find(e => e.id === t)
                            n
                                ? n.quantity++
                                : items.push({ id: t, quantity: 1 })
                        }),
                            ShopifyAPI.addItemFromForm(
                                { data: { items: items }, forms: forms },
                                e => {
                                    if (isDiscountApplicable()) {
                                        const e = getVariantIds(),
                                            t = {
                                                productId: productId,
                                                variantIds: e,
                                                discount: {
                                                    name: discountName,
                                                    type: discountType,
                                                    amount: discountAmount,
                                                },
                                            }
                                        upsellBundleStorage.remove(productId),
                                            upsellBundleStorage.add(t),
                                            discountName &&
                                                discountType &&
                                                discountAmount &&
                                                theme.discountCode.addDiscount(
                                                    discountName,
                                                    discountType,
                                                    discountAmount
                                                ),
                                            theme.discountCode.init()
                                    }
                                    theme.loadingState.init($buttonBundle),
                                        theme.settings.dbtfySkipCart
                                            ? theme.goToCheckoutWithDiscount()
                                            : 'page' === cartType
                                            ? document.body.classList.contains(
                                                  'template-cart'
                                              )
                                                ? theme.ajaxCart.update()
                                                : window.location.assign(
                                                      '/cart'
                                                  )
                                            : 'drawer' === cartType &&
                                              (theme.ajaxCart.update(),
                                              $('body').on(
                                                  'ajaxCart.afterCartLoad',
                                                  function (e, t) {
                                                      theme.loadingState.destroy(
                                                          $buttonBundle
                                                      )
                                                  }
                                              ))
                                },
                                (XMLHttpRequest, textStatus, form) => {
                                    const data = eval(
                                        `(${XMLHttpRequest.responseText})`
                                    )
                                    theme.loadingState.destroy($buttonBundle),
                                        data.message &&
                                            422 === data.status &&
                                            $buttonBundle.insertAdjacentHTML(
                                                'afterend',
                                                `<div class="errors qty-error">${data.description}</div>`
                                            )
                                }
                            )
                    })
                const upsellBundleStorage = {
                    getAll: function () {
                        const e = JSON.parse(
                            sessionStorage.getItem('upsellBundles')
                        )
                        return e || []
                    },
                    get: function (e) {
                        const t = JSON.parse(
                            sessionStorage.getItem('upsellBundles')
                        )
                        return t ? t.find(t => t.productId === +e) : null
                    },
                    add: function (e) {
                        const t = this.getAll() || []
                        t.push(e),
                            sessionStorage.setItem(
                                'upsellBundles',
                                JSON.stringify(t)
                            )
                    },
                    has: function (e) {
                        const t = this.getAll()
                        return !!t && t.some(t => t.productId === +e)
                    },
                    remove: function (e) {
                        const t = this.getAll()
                        if (!t) return
                        const n = t.filter(t => t.productId !== e)
                        return (
                            n.length
                                ? sessionStorage.setItem(
                                      'upsellBundles',
                                      JSON.stringify(n)
                                  )
                                : sessionStorage.removeItem('upsellBundles'),
                            !0
                        )
                    },
                }
                function calculateDiscount(e, t) {
                    let n = null
                    if (
                        (discountBox && (discountBox.style.display = 'none'),
                        bundleTotal.classList.remove('text-sale'),
                        t > e)
                    ) {
                        if ('percentage' === discountVariant) {
                            const o = Math.round((100 * (t - e)) / t)
                            o <= 100 && (n = `${o}%`)
                        } else {
                            const o = t - e
                            o > 0 &&
                                (n = `<span class="money">${theme.Currency.formatMoney(
                                    o,
                                    moneyFormat
                                )}</span>`)
                        }
                        n &&
                            (discountBoxText &&
                                (discountBoxText.innerHTML =
                                    theme.strings.saveFormat.replace(
                                        /\{\{ saved_amount \}\}/,
                                        n
                                    )),
                            bundleTotal.classList.add('text-sale'),
                            discountBox &&
                                (discountBox.style.display = 'block'))
                    }
                }
                function checkPrice() {
                    const e = $container.querySelectorAll(
                        '.form-item-upsell_bundles'
                    ).length
                    let t = 0,
                        n = 0,
                        o = 0
                    if (
                        ($bundleSelect.forEach(e => {
                            if (
                                e
                                    .closest('.form-item-upsell_bundles')
                                    .querySelector('.checkbox-upsell_bundles')
                                    .checked
                            ) {
                                const s = e.options[e.selectedIndex],
                                    l = parseInt(s.dataset.price),
                                    r = parseInt(s.dataset.compareAtPrice)
                                o++, (t += l), (n += r > 0 ? r : l)
                            }
                        }),
                        e === o &&
                            discountName &&
                            discountAmount &&
                            discountType)
                    ) {
                        const e = t
                        switch (discountType) {
                            case '%':
                                t -= (t / 100) * discountAmount
                                break
                            case '$':
                                t -= 100 * discountAmount
                        }
                        t > 0
                            ? ((n = e),
                              $container.setAttribute(
                                  'data-discount-applied',
                                  'true'
                              ))
                            : ((t = e),
                              $container.removeAttribute(
                                  'data-discount-applied'
                              ))
                    }
                    ;(bundleTotal.dataset.total = t),
                        (bundleTotal.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                            t,
                            moneyFormat
                        )}</span>`),
                        t < n
                            ? ((bundleCompareTotal.dataset.total = n),
                              (bundleCompareTotal.innerHTML = `<span class="money">${theme.Currency.formatMoney(
                                  n,
                                  moneyFormat
                              )}</span>`),
                              (bundleCompareTotal.style.display = 'block'))
                            : (bundleCompareTotal.style.display = 'none'),
                        calculateDiscount(t, n)
                    const s = document.querySelector(
                            "#currency-list-header[name='dbtfy-custom-currencies']"
                        ),
                        l = theme.strings.shopCurrency
                    s &&
                        l &&
                        Currency.currentCurrency &&
                        Currency.convertAll(l, Currency.currentCurrency)
                }
                function verifyUnchecked() {
                    const e = document.querySelector('.btn-upsell_bundles'),
                        t = document.querySelectorAll(
                            '.checkbox-upsell_bundles'
                        ),
                        n = [].filter.call(t, function (e) {
                            return !e.checked
                        })
                    t.length === n.length
                        ? (e.classList.add('disabled'),
                          e.setAttribute('disabled', 'disabled'))
                        : (e.classList.remove('disabled'),
                          e.removeAttribute('disabled', 'disabled'))
                }
                function isDiscountApplicable() {
                    const e = document.querySelectorAll(
                            '.checkbox-upsell_bundles'
                        ),
                        t = Boolean(
                            $container.getAttribute('data-discount-applied')
                        )
                    let n = !1
                    return (
                        Array.from(e).reduce(
                            (e, t) => (t.checked ? e + 1 : e),
                            0
                        ) === e.length &&
                            t &&
                            (n = !0),
                        n
                    )
                }
                function getVariantIds() {
                    const e = []
                    return (
                        $bundleSelect.forEach(t => {
                            const n = t.value
                            e.push(+n)
                        }),
                        e
                    )
                }
                function upsellBundleDiscountOnCartUpdate(e) {
                    upsellBundleStorage.getAll().forEach(t => {
                        const n = t.discount,
                            o = t.variantIds.reduce(function (e, t) {
                                return (e[t] = (e[t] || 0) + 1), e
                            }, {})
                        Object.entries(o).every(([t, n]) =>
                            e.items.some(
                                e => e.variant_id === +t && e.quantity >= n
                            )
                        )
                            ? theme.discountCode.hasDiscount(n.name) ||
                              theme.discountCode.addDiscount(
                                  n.name,
                                  n.type,
                                  n.amount
                              )
                            : theme.discountCode.removeDiscount(n.name)
                    }),
                        theme.discountCode.init(),
                        theme.showTheMostValuableDiscountInCart()
                }
                $('body').on('ajaxCart.afterCartLoad', function (e, t) {
                    upsellBundleDiscountOnCartUpdate(t)
                })
            }
            function getRecommendationsSection() {
                fetch(recommendationsSectionUrl)
                    .then(function (e) {
                        return e.text()
                    })
                    .then(function (e) {
                        const t = new DOMParser()
                            .parseFromString(e, 'text/html')
                            .querySelector('#UpsellBundles').innerHTML
                        t &&
                            '' !== t.trim() &&
                            (($container.innerHTML = t), upsellBundleLoaded())
                    })
            }
            $container.classList.contains('specific-upsell_bundles')
                ? upsellBundleLoaded()
                : getRecommendationsSection()
        }
        document.addEventListener('shopify:section:load', e => {
            UpsellBundles(), sessionStorage.removeItem('upsellBundles')
        }),
            UpsellBundles()
    }
    function themeUpsellBundlesDestroy() {
        function destroyUpsellBundles() {
            const e = document.querySelectorAll('.dbtfy-upsell_bundles')
            e.length &&
                e.forEach(e => {
                    e.remove()
                })
        }
        destroyUpsellBundles()
    } /* end-dbtfy-upsell-bundles */

    /* start-dbtfy-upsell-popup */ function themeUpsellPopup() {}
    function themeUpsellPopupDestroy() {} /* end-dbtfy-upsell-popup */

    /* start-dbtfy-wish-list */ function themeWishList() {}
    function themeWishListDestroy() {} /* end-dbtfy-wish-list */

    let addonsLoaded = !1
    document.addEventListener('DOMContentLoaded', function (e) {
        if (addonsLoaded) return
        addonsLoaded = !0
        var t = new theme.Sections()

        const o = {
            dbtfy_addtocart_animation: {
                init: () => {
                    t.register('product-template', themeAddtocartAnimation)
                },
                destroy: () => {},
            },
            dbtfy_age_check: {
                init: themeAgeCheck,
                destroy: themeAgeCheckDestroy,
            },
            dbtfy_agree_to_terms: {
                init: themeAgreeToTerms,
                destroy: themeAgreeToTermsDestroy,
            },
            dbtfy_automatic_geolocation: {
                init: themeAutomaticGeolocation,
                destroy: () => {},
            },
            dbtfy_back_in_stock: {
                init: themeBackInStock,
                destroy: themeBackInStockDestroy,
            },
            dbtfy_cart_countdown: {
                init: themeCartCountdown,
                destroy: themeCartCountdownDestroy,
            },
            dbtfy_cart_discount: {
                init: themeCartDiscount,
                destroy: themeCartDiscountDestroy,
            },
            dbtfy_cart_favicon: {
                init: themeCartFavicon,
                destroy: () => {},
            },
            dbtfy_cart_goal: {
                init: themeCartGoal,
                destroy: themeCartGoalDestroy,
            },
            dbtfy_cart_savings: {
                init: themeCartSavings,
                destroy: themeCartSavingsDestroy,
            },
            dbtfy_cart_upsell: {
                init: themeCartUpsell,
                destroy: themeCartUpsellDestroy,
            },
            dbtfy_collection_addtocart: {
                init: themeCollectionAddtocart,
                destroy: themeCollectionAddtocartDestroy,
            },
            dbtfy_collection_filters: {
                init: themeCollectionFilters,
                destroy: themeCollectionFiltersDestroy,
            },
            dbtfy_color_swatches: {
                init: themeColorSwatches,
                destroy: themeColorSwatchesDestroy,
            },
            dbtfy_cookie_box: {
                init: themeCookieBox,
                destroy: themeCookieBoxDestroy,
            },
            dbtfy_custom_currencies: {
                init: themeCustomCurrencies,
                destroy: themeCustomCurrenciesDestroy,
            },
            dbtfy_customizable_products: {
                init: themeCustomizableProducts,
                destroy: themeCustomizableProductsDestroy,
            },
            dbtfy_delivery_date: {
                init: themeDeliveryDate,
                destroy: themeDeliveryDateDestroy,
            },
            dbtfy_delivery_time: {
                init: themeDeliveryTime,
                destroy: themeDeliveryTimeDestroy,
            },
            dbtfy_discount_saved: {
                init: themeDiscountSaved,
                destroy: themeDiscountSavedDestroy,
            },
            dbtfy_faq_page: {
                init: () => {
                    t.register('faq-page', themeFaqPage)
                },
                destroy: themeFaqPageDestroy,
            },
            dbtfy_facebook_messenger: {
                init: () => {},
                destroy: themeFacebookMessengerDestroy,
            },
            dbtfy_gift_wrap: {
                init: themeGiftWrap,
                destroy: themeGiftWrapDestroy,
            },
            dbtfy_inactive_tab_message: {
                init: themeInactiveTabMessage,
                destroy: () => {},
            },
            dbtfy_infinite_scroll: {
                init: themeInfiniteScroll,
                destroy: themeInfiniteScrollDestroy,
            },
            dbtfy_instagram_feed: {
                init: () => {
                    t.register('instagram-feed', themeInstagramFeed)
                },
                destroy: themeInstagramFeedDestroy,
            },
            dbtfy_inventory_quantity: {
                init: themeInventoryQuantity,
                destroy: themeInventoryQuantityDestroy,
            },
            dbtfy_linked_options: {
                init: themeLinkedOptions,
                destroy: () => {},
            },
            dbtfy_live_view: {
                init: () => {
                    t.register('product-template', themeLiveView)
                },
                destroy: themeLiveViewDestroy,
            },
            dbtfy_mega_menu: {
                init: () => {
                    t.register('header-section', themeMegaMenu)
                },
                destroy: themeMegaMenuDestroy,
            },
            dbtfy_menu_bar: {
                init: () => {},
                destroy: themeMenuBarDestroy,
            },
            dbtfy_newsletter_popup: {
                init: themeNewsletterPopup,
                destroy: themeNewsletterPopupDestroy,
            },
            dbtfy_order_feedback: {
                init: themeOrderFeedback,
                destroy: themeOrderFeedbackDestroy,
            },
            dbtfy_order_tracking: {
                init: themeOrderTracking,
                destroy: themeOrderTrackingDestroy,
            },
            dbtfy_page_speed_booster: {
                init: themePageSpeedBooster,
                destroy: () => {},
            },
            dbtfy_page_transition: {
                init: themePageTransition,
                destroy: themePageTransitionDestroy,
            },
            dbtfy_pricing_table: {
                init: () => {},
                destroy: themePricingTableDestroy,
            },
            dbtfy_product_bullet_points: {
                init: themeProductBulletPoints,
                destroy: themeProductBulletPointsDestroy,
            },
            dbtfy_product_image_crop: {
                init: () => {},
                destroy: themeProductImageCropDestroy,
            },
            dbtfy_product_swatches: {
                init: themeProductSwatches,
                destroy: themeProductSwatchesDestroy,
            },
            dbtfy_product_tabs: {
                init: themeProductTabs,
                destroy: themeProductTabsDestroy,
            },
            dbtfy_quantity_breaks: {
                init: themeQuantityBreaks,
                destroy: themeQuantityBreaksDestroy,
            },
            dbtfy_quick_view: {
                init: themeQuickView,
                destroy: themeQuickViewDestroy,
            },
            dbtfy_recently_viewed: {
                init: themeRecentlyViewed,
                destroy: themeRecentlyViewedDestroy,
            },
            dbtfy_sales_countdown: {
                init: themeSalesCountdown,
                destroy: themeSalesCountdownDestroy,
            },
            dbtfy_sales_pop: {
                init: themeSalesPop,
                destroy: themeSalesPopDestroy,
            },
            dbtfy_shop_protect: {
                init: themeShopProtect,
                destroy: () => {},
            },
            dbtfy_size_chart: {
                init: themeSizeChart,
                destroy: themeSizeChartDestroy,
            },
            dbtfy_smart_search: {
                init: themeSmartSearch,
                destroy: themeSmartSearchDestroy,
            },
            dbtfy_social_discount: {
                init: () => {
                    t.register('product-template', themeSocialDiscount)
                },
                destroy: themeSocialDiscountDestroy,
            },
            dbtfy_sold_items: {
                init: themeSoldItems,
                destroy: themeSoldItemsDestroy,
            },
            dbtfy_sticky_addtocart: {
                init: () => {
                    t.register('product-template', themeStickyAddtocart)
                },
                destroy: themeStickyAddtocartDestroy,
            },
            dbtfy_synced_variant_image: {
                init: themeSyncedVariantImage,
                destroy: () => {},
            },
            dbtfy_trust_badge: {
                init: () => {},
                destroy: themeTrustBadgeDestroy,
            },
            dbtfy_upsell_bundles: {
                init: themeUpsellBundles,
                destroy: themeUpsellBundlesDestroy,
            },
            dbtfy_upsell_popup: {
                init: () => {
                    t.register('upsell-popup', themeUpsellPopup)
                },
                destroy: themeUpsellPopupDestroy,
            },
            dbtfy_wish_list: {
                init: themeWishList,
                destroy: themeWishListDestroy,
            },
        }
        // r = e.detail.enabledAddons
        for (const [e, t] of Object.entries(o)) {
            // r.some(t => t.name === e) ? t.init() : t.destroy()
            t.init()
        }
    })
})()
